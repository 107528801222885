import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Bell, LogOut, Palette } from 'lucide-react';
import { useAuth } from '../../../../contexts/AuthContext';
import SlideMenu from './SlideMenu';
import ThemeCustomizer from '../themes/ThemeCustomizer';
import NotificationsPanel from './NotificationsPanel';
import './AppHeader.css';

const AppHeader = ({
  onToggleSidebar,
  onToggleNotifications,
  notificationsCount = 0,
  isSidebarOpen,
  isNotificationsOpen,
  onCloseNotifications,
  notifications = [], // Add default value
  onMarkAsRead, // Passed from parent
  onClear, // Passed from parent
  onClearAll, // Passed from parent
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isThemeCustomizerOpen, setIsThemeCustomizerOpen] = useState(false);
  const themeButtonRef = useRef(null);

  const handleClickOutside = (event) => {
    if (themeButtonRef.current && !themeButtonRef.current.contains(event.target)) {
      setIsThemeCustomizerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleThemeToggle = (e) => {
    e.stopPropagation();
    setIsThemeCustomizerOpen(!isThemeCustomizerOpen);
    if (isNotificationsOpen) {
      onCloseNotifications?.();
    }
  };

  const handleNotificationsToggle = () => {
    if (isThemeCustomizerOpen) {
      setIsThemeCustomizerOpen(false);
    }
    onToggleNotifications?.();
  };

  const handleLogout = async () => {
    try {
      await logout();
      localStorage.removeItem('authToken');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  return (
    <header className="app-header">
      <div className="header-left">
        <SlideMenu isOpen={isSidebarOpen} onClick={onToggleSidebar} />
      </div>

      <div className="header-right">
        <button
          ref={themeButtonRef}
          onClick={handleThemeToggle}
          className={`theme-trigger ${isThemeCustomizerOpen ? 'active' : ''}`}
          aria-label="Customize theme"
        >
          <Palette size={24} />
        </button>

        <button
          onClick={handleNotificationsToggle}
          className={`notifications-trigger ${isNotificationsOpen ? 'active' : ''}`}
        >
          <Bell size={24} />
          {notificationsCount > 0 && (
            <span className="notifications-badge">{notificationsCount}</span>
          )}
        </button>

        <button onClick={handleLogout} className="logout-button">
          <LogOut size={24} />
        </button>
      </div>

      <ThemeCustomizer
        isOpen={isThemeCustomizerOpen}
        onClose={() => setIsThemeCustomizerOpen(false)}
        triggerRef={themeButtonRef}
      />

      {isNotificationsOpen && (
        <NotificationsPanel
          notifications={notifications}
          onMarkAsRead={onMarkAsRead}
          onClear={onClear}
          onClearAll={onClearAll}
          onCloseNotifications={onCloseNotifications}
          isNotificationsOpen={isNotificationsOpen}
          setIsThemeCustomizerOpen={setIsThemeCustomizerOpen}
        />
      )}
    </header>
  );
};

export default AppHeader;