import React, { useRef, useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { useTheme, THEMES } from '../contexts/ThemeContext';
import './theme-customizer.css';

const ThemeCustomizer = ({ isOpen, onClose, triggerRef }) => {
  const { themeConfig, setTheme } = useTheme();
  const panelRef = useRef(null);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  const updatePosition = () => {
    if (triggerRef?.current && panelRef?.current && isOpen) {
      const triggerBounds = triggerRef.current.getBoundingClientRect();
      const panelWidth = panelRef.current.offsetWidth || 240;
      const panelHeight = panelRef.current.offsetHeight || 200;

      const left = Math.max(
        Math.min(
          triggerBounds.left + (triggerBounds.width / 2) - (panelWidth / 2),
          window.innerWidth - panelWidth - 16
        ),
        16
      );

      const top = Math.min(
        triggerBounds.bottom + 8,
        window.innerHeight - panelHeight - 16
      );

      setPosition({ top, left });
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(updatePosition, 0);
    
    const handleScroll = () => {
      requestAnimationFrame(updatePosition);
    };
    
    const handleResize = () => {
      requestAnimationFrame(updatePosition);
    };
    
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [triggerRef, isOpen]);

  const handleThemeSelect = (themeKey) => {
    setTheme(themeKey);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={panelRef}
          className="theme-customizer-panel visible"
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          transition={{ type: 'spring', stiffness: 300, damping: 20 }}
          style={{
            position: 'fixed',
            top: `${position.top}px`,
            left: `${position.left}px`,
            zIndex: 1000
          }}
        >
          <div className="panel-header">
            <h3>Choose Theme</h3>
            <button
              onClick={onClose}
              className="panel-close-btn"
              aria-label="Close Theme Customizer"
            >
              <X size={16} />
            </button>
          </div>
          <div className="panel-section">
            <div className="color-theme-grid">
              {Object.entries(THEMES).map(([key, theme]) => (
                <button
                  key={key}
                  onClick={() => handleThemeSelect(key)}
                  className={`color-theme-button ${
                    themeConfig.theme === key ? 'active' : ''
                  }`}
                  aria-label={`Select ${theme.name} theme`}
                >
                  <div
                    className="color-preview"
                    style={{
                      background: theme.colors['--primary']
                    }}
                  />
                </button>
              ))}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ThemeCustomizer;