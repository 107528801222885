import React, { useState } from 'react';
import { Bell, User, Database } from 'lucide-react';
import './Settings.css';

const SettingsPage = () => {
  // Account Settings State
  const [accountSettings, setAccountSettings] = useState({
    email: '',
    currentPassword: '',
    newPassword: '',
    phone: ''
  });

  // Notification Settings State
  const [notifications, setNotifications] = useState({
    email: true,
    sms: false
  });

  // Data Collection State
  const [dataCollection, setDataCollection] = useState(true);

  // Handle account settings changes
  const handleAccountChange = (field, value) => {
    setAccountSettings(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // Handle form submissions
  const handleUpdate = (type) => {
    switch (type) {
      case 'email':
        if (!accountSettings.email) return alert('Please enter a valid email.');
        break;
      case 'password':
        if (!accountSettings.currentPassword || !accountSettings.newPassword) {
          return alert('Please fill in both password fields.');
        }
        break;
      case 'phone':
        if (!accountSettings.phone) return alert('Please enter a valid phone number.');
        break;
      default:
        break;
    }
    console.log(`${type} updated successfully!`);
  };

  return (
    <div className="settings-page">
      <div className="settings-container">
        <h1 className="settings-title">Settings</h1>

        {/* Account Section */}
        <div className="settings-group">
          <h2 className="settings-subtitle">
            <User size={24} /> Account
          </h2>

          <div className="settings-item">
            <label>Email Address</label>
            <input
              type="email"
              value={accountSettings.email}
              onChange={(e) => handleAccountChange('email', e.target.value)}
              placeholder="Enter your email"
              className="settings-input"
            />
            <button onClick={() => handleUpdate('email')} className="settings-btn">
              Update
            </button>
          </div>

          <div className="settings-item">
            <label>Change Password</label>
            <input
              type="password"
              value={accountSettings.currentPassword}
              onChange={(e) => handleAccountChange('currentPassword', e.target.value)}
              placeholder="Current Password"
              className="settings-input"
            />
            <input
              type="password"
              value={accountSettings.newPassword}
              onChange={(e) => handleAccountChange('newPassword', e.target.value)}
              placeholder="New Password"
              className="settings-input"
            />
            <button onClick={() => handleUpdate('password')} className="settings-btn">
              Update Password
            </button>
          </div>

          <div className="settings-item">
            <label>Phone Number</label>
            <input
              type="tel"
              value={accountSettings.phone}
              onChange={(e) => handleAccountChange('phone', e.target.value)}
              placeholder="+1 (555) 000-0000"
              className="settings-input"
            />
            <button onClick={() => handleUpdate('phone')} className="settings-btn">
              Update
            </button>
          </div>
        </div>

        {/* Notifications Section */}
        <div className="settings-group">
          <h2 className="settings-subtitle">
            <Bell size={24} /> Notifications
          </h2>

          <div className="settings-item checkbox">
            <label>Email Notifications</label>
            <input
              type="checkbox"
              checked={notifications.email}
              onChange={(e) => setNotifications(prev => ({
                ...prev,
                email: e.target.checked
              }))}
              className="settings-checkbox"
            />
          </div>

          <div className="settings-item checkbox">
            <label>SMS Notifications</label>
            <input
              type="checkbox"
              checked={notifications.sms}
              onChange={(e) => setNotifications(prev => ({
                ...prev,
                sms: e.target.checked
              }))}
              className="settings-checkbox"
            />
          </div>
        </div>

        {/* Data Privacy Section */}
        <div className="settings-group">
          <h2 className="settings-subtitle">
            <Database size={24} /> Data & Privacy
          </h2>

          <div className="settings-item checkbox">
            <label>Data Collection</label>
            <input
              type="checkbox"
              checked={dataCollection}
              onChange={(e) => setDataCollection(e.target.checked)}
              className="settings-checkbox"
            />
          </div>

          <div className="settings-actions">
            <button className="danger-btn">Delete Account</button>
            <button className="secondary-btn">Export Data</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;