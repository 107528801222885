import * as yup from 'yup';

// Signup form validation schema
export const signupSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email') // Ensure it's a valid email
    .required('Email is required'), // Email is required
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters') // Firebase minimum
    .matches(/[A-Z]/, 'Password must include an uppercase letter') // At least 1 uppercase
    .matches(/[a-z]/, 'Password must include a lowercase letter') // At least 1 lowercase
    .matches(/[0-9]/, 'Password must include a number') // At least 1 digit
    .matches(/[@$!%*?&]/, 'Password must include a special character') // At least 1 special char
    .required('Password is required'), // Password is required
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match') // Confirm password matches
    .required('Confirm Password is required'), // Confirm password required
});

// Login Validation Schema
export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .email('Please enter a valid email')
    .required('Email is required'),
  password: yup.string().required('Password is required'),
});