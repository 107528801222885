// VerificationConfirmationPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const VerificationConfirmationPage = () => {
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const checkAndRedirect = async () => {
      if (user?.emailVerified) {
        // If verified, go to dashboard
        navigate('/app');
      }
    };

    checkAndRedirect();
  }, [user, navigate]);

  // Show a loading state while checking/redirecting
  return (
    <div className="verification-confirmation-page">
      <div className="verification-container">
        <h1 className="title">Verification Success!</h1>
        <p className="subtitle">
          Redirecting you to your personal dashboard...
        </p>
        {/* Optional loading spinner */}
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
};

export default VerificationConfirmationPage;