import React, { useState, useEffect, useCallback } from "react";
import { Clock, CheckCircle, Calendar, Timer, TrendingUp, X } from "lucide-react";
import useCalendar, { MONTH_NAMES, DAYS } from "./hooks/useCalendar";
import './MoodTrackerPage.css';
import { useTheme, THEMES } from '../../../Dashboard/components/contexts/ThemeContext';

function formatTimeToAmPm(time) {
  const [hourStr, minute] = time.split(":");
  let hour = parseInt(hourStr, 10);
  const ampm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12;
  return `${hour}:${minute} ${ampm}`;
}

const TIME_SLOTS = ["06:00", "07:00", "08:00", "12:00", "15:00", "18:00", "20:00", "22:00"];

const generatePastData = () => {
  const pastData = [];
  const today = new Date();
  const sessionTypes = [
    { title: "Morning Meditation", duration: "15 min" },
    { title: "Mindful Break", duration: "5 min" },
    { title: "Evening Relaxation", duration: "20 min" },
  ];

  for (let i = 30; i >= 1; i--) {
    const date = new Date(today);
    date.setDate(date.getDate() - i);
    const dateStr = date.toISOString().split("T")[0];

    if (Math.random() > 0.3) {
      const sessionsCount = Math.floor(Math.random() * 2) + 1;
      const sessions = [];
      for (let j = 0; j < sessionsCount; j++) {
        const sessionType = sessionTypes[Math.floor(Math.random() * sessionTypes.length)];
        const randomTime = TIME_SLOTS[Math.floor(Math.random() * TIME_SLOTS.length)];
        sessions.push({
          id: `past-${dateStr}-${j}`,
          time: randomTime,
          ...sessionType,
          completed: true,
        });
      }
      pastData.push({ date: dateStr, sessions });
    }
  }
  return pastData;
};

export default function WellnessTracker() {
  const { themeConfig } = useTheme();
  const calendar = useCalendar();
  const [selectedTime, setSelectedTime] = useState("");
  const [sessions, setSessions] = useState({
    today: [],
    upcoming: [],
    past: generatePastData(),
  });
  const [stats, setStats] = useState({
    totalSessions: 0,
    totalMinutes: 0,
    completionRate: 0,
    averagePerWeek: 0,
  });

  useEffect(() => {
    const root = document.documentElement;
    const selectedThemeObj = THEMES[themeConfig.theme];
    
    if (selectedThemeObj) {
      Object.entries(selectedThemeObj.colors).forEach(([key, value]) => {
        root.style.setProperty(key, value);
      });
    }
    
    localStorage.setItem('themeConfig', JSON.stringify(themeConfig));
  }, [themeConfig]);

  useEffect(() => {
    const handleMouseMove = (event) => {
      const { clientX, clientY } = event;
      const x = (clientX / window.innerWidth) * 100;
      const y = (clientY / window.innerHeight) * 100;
      document.documentElement.style.setProperty('--mouse-x', `${x}%`);
      document.documentElement.style.setProperty('--mouse-y', `${y}%`);
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleDeleteSession = useCallback((sessionId, sessionType) => {
    setSessions((prev) => ({
      ...prev,
      [sessionType]: prev[sessionType].filter((s) => s.id !== sessionId),
    }));
  }, []);

  const handleScheduleSession = useCallback(() => {
    if (!calendar.selectedDate || !selectedTime) return;
  
    const today = new Date();
    const todayString = today.toLocaleDateString('en-CA');
    
    const newSession = {
      id: Date.now().toString(),
      date: calendar.selectedDate,
      time: selectedTime,
      title: "Meditation Session",
      duration: "15 min",
      completed: false,
    };
  
    const isToday = calendar.selectedDate === todayString;
    const sessionType = isToday ? "today" : "upcoming";
  
    setSessions((prev) => ({
      ...prev,
      [sessionType]: [...prev[sessionType], newSession].sort((a, b) => {
        if (a.date !== b.date) {
          return new Date(a.date) - new Date(b.date);
        }
        return a.time.localeCompare(b.time);
      }),
    }));
  
    setSelectedTime("");
  }, [calendar.selectedDate, selectedTime]);

  useEffect(() => {
    const pastSessionsFlat = sessions.past.flatMap((day) => day.sessions);
    const totalSessions = pastSessionsFlat.length;
    const totalMinutes = pastSessionsFlat.reduce(
      (sum, session) => sum + parseInt(session.duration),
      0
    );
    const completedSessions = pastSessionsFlat.filter((s) => s.completed).length;
    const weeksTracked = Math.max(1, Math.ceil(sessions.past.length / 7));

    setStats({
      totalSessions,
      totalMinutes,
      completionRate: totalSessions > 0 ? Math.round((completedSessions / totalSessions) * 100) : 0,
      averagePerWeek: Math.round(totalSessions / weeksTracked),
    });
  }, [sessions.past]);

  const formatDisplayDate = (dateString) => {
    if (!dateString) return '';
    
    try {
      // If it's already a Date object, use it directly
      const date = dateString instanceof Date ? dateString : new Date(dateString);
      
      // Validate the date
      if (isNaN(date.getTime())) {
        throw new Error('Invalid date');
      }
      
      return date.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (e) {
      console.error('Date formatting error:', e);
      return dateString instanceof Date ? dateString.toLocaleDateString() : dateString;
    }
  };


  return (
    <div className="wellness-container">
      <div className="wellness-page">
        <div className="wellness-aurora-bg"></div>
        <div className="wellness-content-wrapper">
          <div className="wellness-content">
            {/* Left Panel */}
            <div className="wellness-panel-left">
              <div className="wellness-calendar-container">
                <div className="wellness-calendar">
                  <div className="wellness-calendar-header">
                    <button
                      className="wellness-nav-button"
                      onClick={calendar.goToPreviousMonth}
                    >
                      ←
                    </button>
                    <div className="wellness-month-picker">
                      <span>{MONTH_NAMES[calendar.currentMonth]}</span>
                      <span className="wellness-year">{calendar.currentYear}</span>
                    </div>
                    <button
                      className="wellness-nav-button"
                      onClick={calendar.goToNextMonth}
                    >
                      →
                    </button>
                  </div>
                  <div className="wellness-calendar-weekday">
                    {DAYS.map((day) => (
                      <div key={day}>{day}</div>
                    ))}
                  </div>
                  <div className="wellness-calendar-days">
                    {calendar.generateCalendarDays()}
                  </div>
                </div>
              </div>

              <div className="wellness-stats-panel">
                <h3>Meditation Stats</h3>
                <div className="wellness-stats-grid">
                  <div className="wellness-stat-item">
                    <Calendar size={20} />
                    <div className="wellness-stat-info">
                      <span>Avg Weekly</span>
                      <strong>{stats.averagePerWeek} sessions</strong>
                    </div>
                  </div>
                  <div className="wellness-stat-item">
                    <Timer size={20} />
                    <div className="wellness-stat-info">
                      <span>Total Time</span>
                      <strong>{stats.totalMinutes} min</strong>
                    </div>
                  </div>
                  <div className="wellness-stat-item">
                    <TrendingUp size={20} />
                    <div className="wellness-stat-info">
                      <span>Completion Rate</span>
                      <strong>{stats.completionRate}%</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Panel */}
            <div className="wellness-panel-right">
              <div className="wellness-meditation-scheduler">
                <h3>Schedule Meditation</h3>

                {calendar.selectedDate && (
                  <div className="wellness-scheduling-header">
                    <div className="wellness-scheduling-date">
                      Scheduling for: {formatDisplayDate(calendar.selectedDate)}
                    </div>
                  </div>
                )}

                {calendar.selectedDate ? (
                  <>
                    <h4>Select a Time:</h4>
                    <div className="wellness-time-slots">
                      {TIME_SLOTS.map((time) => (
                        <button
                          key={time}
                          className={`wellness-time-slot ${
                            selectedTime === time ? "wellness-time-slot-active" : ""
                          }`}
                          onClick={() => setSelectedTime(time)}
                          aria-label={`Select time ${formatTimeToAmPm(time)}`}
                        >
                          {formatTimeToAmPm(time)}
                        </button>
                      ))}
                    </div>

                    <button
                      className="wellness-schedule-button"
                      onClick={handleScheduleSession}
                      disabled={!selectedTime}
                      aria-disabled={!selectedTime}
                    >
                      Schedule Session
                    </button>

                    <div className="wellness-sessions-section">
                    <h4>Today's Sessions ({new Date().toLocaleDateString('en-US', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric'
                        })})</h4>
                        <div className="wellness-sessions-list">
                        {sessions.today.length === 0 ? (
                          <div className="wellness-no-sessions">No sessions scheduled for today.</div>
                        ) : (
                          sessions.today.map((session) => (
                            <div
                              key={session.id}
                              className="wellness-session-item wellness-session-today"
                            >
                              <div className="wellness-session-time">
                                <Clock size={16} />
                                <span>
                                  <strong>Time:</strong> {formatTimeToAmPm(session.time)}
                                </span>
                              </div>
                              <div className="wellness-session-info">
                                <span className="wellness-session-title">{session.title}</span>
                                <span className="wellness-duration">
                                  <strong>Duration:</strong> {session.duration}
                                </span>
                              </div>
                              <div className="wellness-session-controls">
                                <CheckCircle size={20} className="wellness-pending-icon" />
                                <X
                                  size={18}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteSession(session.id, "today");
                                  }}
                                  className="wellness-delete-btn"
                                />
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    </div>

                    <div className="wellness-sessions-section">
                      <h4>Upcoming Sessions</h4>
                      <div className="wellness-sessions-list">
                        {sessions.upcoming.length === 0 ? (
                          <div className="wellness-no-sessions">No upcoming sessions scheduled.</div>
                        ) : (
                          sessions.upcoming.reduce((acc, session, index, array) => {
                            const isNewDate =
                              index === 0 || session.date !== array[index - 1].date;

                            if (isNewDate) {
                              acc.push(
                                <div
                                  key={`date-${session.date}`}
                                  className="wellness-date-label"
                                >
                                  {formatDisplayDate(session.date)}
                                </div>
                              );
                            }

                            acc.push(
                              <div
                                key={session.id}
                                className="wellness-session-item wellness-session-future"
                              >
                                <div className="wellness-session-time">
                                  <Clock size={16} />
                                  <span>
                                    <strong>Time:</strong> {formatTimeToAmPm(session.time)}
                                  </span>
                                </div>
                                <div className="wellness-session-info">
                                  <span className="wellness-session-title">{session.title}</span>
                                  <span className="wellness-duration">
                                    <strong>Duration:</strong> {session.duration}
                                  </span>
                                </div>
                                <div className="wellness-session-controls">
                                  <CheckCircle size={20} className="wellness-pending-icon" />
                                  <X
                                    size={18}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteSession(session.id, "upcoming");
                                    }}
                                    className="wellness-delete-btn"
                                  />
                                </div>
                              </div>
                            );

                            return acc;
                          }, [])
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="wellness-select-prompt">
                    <Calendar size={48} />
                    <p>Select a date to schedule sessions</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}