import React, { createContext, useContext, useState, useEffect } from 'react';



export const THEMES = {
  CYBER_PURPLE: {
    name: 'cyberPurple',
    colors: {
      '--primary': '#6A00FF',
      '--primary-rgb': '106, 0, 255',
      '--secondary': 'rgba(106, 0, 255, 0.4)',
      '--hover-overlay': 'rgba(106, 0, 255, 0.15)',
      '--text-primary': 'rgba(255, 255, 255, 0.9)',
      '--text-secondary': 'rgba(255, 255, 255, 0.6)',
      '--bg-primary': 'rgba(0, 0, 0, 0.95)',
      '--bg-primary-rgb': '0, 0, 0',
      '--active-bg': 'rgba(106, 0, 255, 0.25)',
      '--notification-badge': '#FF1493',
      '--active-icon': '#3D0073',
    },
  },
  NEO_TEAL: {
    name: 'neoTeal',
    colors: {
      '--primary': 'rgb(0, 255, 200)',
      '--primary-rgb': '0, 255, 200',
      '--secondary': 'rgba(0, 255, 200, 0.3)',
      '--hover-overlay': 'rgba(0, 255, 200, 0.2)',
      '--text-primary': 'rgba(255, 255, 255, 0.9)',
      '--text-secondary': 'rgba(255, 255, 255, 0.6)',
      '--bg-primary': 'rgba(0, 10, 10, 0.95)',
      '--bg-primary-rgb': '0, 10, 10',
      '--active-bg': 'rgba(0, 255, 200, 0.4)',
      '--notification-badge': '#FF1493',
      '--active-icon': '#007F66',
    },
  },
  AZURE_BLUE: {
    name: 'azureBlue',
    colors: {
      '--primary': 'rgb(0, 194, 255)',
      '--primary-rgb': '0, 194, 255',
      '--secondary': 'rgba(0, 194, 255, 0.4)',
      '--hover-overlay': 'rgba(0, 194, 255, 0.15)',
      '--text-primary': 'rgba(255, 255, 255, 0.9)',
      '--text-secondary': 'rgba(255, 255, 255, 0.6)',
      '--bg-primary': 'rgba(0, 0, 20, 0.95)',
      '--bg-primary-rgb': '0, 0, 20',
      '--active-bg': 'rgba(0, 194, 255, 0.25)',
      '--notification-badge': '#FF1493',
      '--active-icon': '#004F99',
    },
  },
};

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const [themeConfig, setThemeConfig] = useState(() => {
    try {
      const savedConfig = localStorage.getItem('themeConfig');
      if (savedConfig) {
        const parsedConfig = JSON.parse(savedConfig);
        if (parsedConfig.theme in THEMES) {
          return parsedConfig;
        }
      }
    } catch (error) {
      console.error('Error loading theme config:', error);
    }
    return {
      theme: 'CYBER_PURPLE'
    };
  });

  useEffect(() => {
    const root = document.documentElement;
    const selectedTheme = THEMES[themeConfig.theme];
    
    if (selectedTheme) {
      Object.entries(selectedTheme.colors).forEach(([key, value]) => {
        root.style.setProperty(key, value);
      });
    }

    localStorage.setItem('themeConfig', JSON.stringify(themeConfig));
  }, [themeConfig]);

  const setTheme = (theme) => {
    if (THEMES[theme]) {
      setThemeConfig(prev => ({ ...prev, theme }));
    }
  };

  return (
    <ThemeContext.Provider value={{ themeConfig, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};

export default ThemeProvider;