import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
  sendEmailVerification, // Ensure this is included

} from 'firebase/auth';
import { auth } from '../../utils/firebase';
import './Signup.css';
import logo from '../../assets/logo_transparent.png';

const SignupPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmPassword: '',
    meditationGoal: '',
    experienceLevel: 'beginner',
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear errors when user starts typing
    if (error) setError('');
  };

  // Handle social logins (Google and Apple)
  const handleSocialLogin = async (provider) => {
    setIsLoading(true);
    try {
      const authProvider =
        provider === 'google' ? new GoogleAuthProvider() : new OAuthProvider('apple.com');
      const result = await signInWithPopup(auth, authProvider);
      if (result.user) {
        toast.success(`Welcome, ${result.user.displayName || 'User'}! Redirecting...`);
        navigate('/app');
      }
    } catch (err) {
      console.error(`${provider} Sign-in Error:`, err);
      setError(`${provider} sign-in failed. Please try again.`);
      toast.error(`${provider} sign-in failed. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  // Validate password fields
  const validatePassword = () => {
    const { password, confirmPassword } = formData;
    if (password.length < 8) {
      setError('Password must be at least 8 characters long.');
      toast.error('Password must be at least 8 characters long.');
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      setError('Password must include at least one uppercase letter.');
      toast.error('Password must include at least one uppercase letter.');
      return false;
    }
    if (!/[0-9]/.test(password)) {
      setError('Password must include at least one number.');
      toast.error('Password must include at least one number.');
      return false;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      toast.error('Passwords do not match.');
      return false;
    }
    return true;
  };

  // Handle proceeding to the next step
  const handleNextStep = () => {
    if (step === 1) {
      if (!formData.firstName || !formData.lastName || !formData.email) {
        setError('Please fill in all fields.');
        toast.error('Please fill in all fields.');
        return;
      }
      if (!formData.email.includes('@')) {
        setError('Please enter a valid email address.');
        toast.error('Please enter a valid email address.');
        return;
      }
    }
    if (step === 2 && !validatePassword()) return;

    setError('');
    setStep((prev) => prev + 1);
  };

  // Handle going back to the previous step
  const handlePrevStep = () => {
    setError('');
    setStep((prev) => prev - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const { email, password } = formData;
  
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
  
      // Send verification email
      await sendEmailVerification(userCredential.user);
  
      // Show success message
      toast.success(
        "Account created! A verification email has been sent to your inbox. Please verify your email.",
        { position: "top-center", autoClose: 5000 }
      );
  
      // Redirect to Check Email page
      navigate("/check-email");
    } catch (err) {
      console.error("Signup Error:", err);
  
      switch (err.code) {
        case "auth/email-already-in-use":
          toast.error("This email is already registered. Try logging in instead.");
          break;
        case "auth/invalid-email":
          toast.error("Invalid email address. Please enter a valid email.");
          break;
        case "auth/weak-password":
          toast.error(
            "Weak password! Use at least 8 characters, including uppercase letters, numbers, and special characters."
          );
          break;
        case "auth/network-request-failed":
          toast.error("Network error! Please check your connection and try again.");
          break;
        default:
          toast.error("Something went wrong. Please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Render Step 1: Basic Information
  const renderStep1 = () => (
    <>
      <div className="form-group">
        <label htmlFor="firstName">First Name</label>
        <input
          type="text"
          id="firstName"
          name="firstName"
          value={formData.firstName}
          onChange={handleChange}
          className="auth-input"
          placeholder="Enter your first name"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="lastName">Last Name</label>
        <input
          type="text"
          id="lastName"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
          className="auth-input"
          placeholder="Enter your last name"
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="auth-input"
          placeholder="Enter your email"
          required
        />
      </div>
    </>
  );

  // Render Step 2: Password Setup
  const renderStep2 = () => (
    <>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="auth-input"
          placeholder="Create a password"
          required
        />
        <div className="password-requirements">
          <p>Password must:</p>
          <ul>
            <li className={formData.password.length >= 8 ? 'met' : ''}>
              Be at least 8 characters
            </li>
            <li className={/[A-Z]/.test(formData.password) ? 'met' : ''}>
              Include an uppercase letter
            </li>
            <li className={/[0-9]/.test(formData.password) ? 'met' : ''}>
              Include a number
            </li>
          </ul>
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="confirmPassword">Confirm Password</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleChange}
          className="auth-input"
          placeholder="Confirm your password"
          required
        />
      </div>
    </>
  );

  // Render Step 3: Personalization
  const renderStep3 = () => (
    <>
      <div className="form-group">
        <label htmlFor="meditationGoal">What's your meditation goal?</label>
        <select
          id="meditationGoal"
          name="meditationGoal"
          value={formData.meditationGoal}
          onChange={handleChange}
          className="auth-input"
          required
        >
          <option value="">Select a goal</option>
          <option value="stress">Reduce Stress</option>
          <option value="focus">Improve Focus</option>
          <option value="sleep">Better Sleep</option>
          <option value="anxiety">Manage Anxiety</option>
          <option value="general">General Wellbeing</option>
        </select>
      </div>
      <div className="form-group">
        <label>What's your meditation experience level?</label>
        <div className="experience-buttons">
          {['beginner', 'intermediate', 'advanced'].map((level) => (
            <button
              key={level}
              type="button"
              className={`experience-button ${
                formData.experienceLevel === level ? 'active' : ''
              }`}
              onClick={() =>
                setFormData((prev) => ({ ...prev, experienceLevel: level }))
              }
            >
              {level.charAt(0).toUpperCase() + level.slice(1)}
            </button>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <div className="signup-page">
      {/* Background Layer */}
      <div className="background-layer"></div>

      {/* Logo Section */}
      <Link to="/" className="logo-section">
        <img src={logo} alt="Pause Logo" className="auth-logo" />
      </Link>

      {/* Auth Container */}
      <div className="auth-container">
        <div className="auth-content">
          {/* Step Indicator */}
          <div className="step-indicator">
            {[1, 2, 3].map((s) => (
              <div
                key={s}
                className={`step ${
                  s === step ? 'active' : ''
                } ${s < step ? 'completed' : ''}`}
              >
                {s < step ? '✓' : s}
              </div>
            ))}
          </div>

          {/* Title and Subtitle */}
          <h1 className="auth-title">
            {step === 1 && 'Create Account'}
            {step === 2 && 'Secure Your Account'}
            {step === 3 && 'Personalize Your Journey'}
          </h1>

          <p className="auth-subtitle">
            {step === 1 && 'Start your meditation journey'}
            {step === 2 && 'Choose a strong password'}
            {step === 3 && 'Help us customize your experience'}
          </p>

          {/* Error Message */}
          {error && <div className="error-message">{error}</div>}

          {/* Signup Form */}
          <form onSubmit={handleSubmit} className="auth-form">
            {step === 1 && renderStep1()}
            {step === 2 && renderStep2()}
            {step === 3 && renderStep3()}

            {/* Form Buttons */}
            <div className="form-buttons">
              {step > 1 && (
                <button
                  type="button"
                  onClick={handlePrevStep}
                  className="auth-button secondary"
                >
                  Back
                </button>
              )}

              {step < 3 ? (
                <button
                  type="button"
                  onClick={handleNextStep}
                  className="auth-button"
                >
                  Next
                </button>
              ) : (
                <button
                  type="submit"
                  className={`auth-button ${isLoading ? 'loading' : ''}`}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <div className="loading-spinner"></div>
                  ) : (
                    'Create Account'
                  )}
                </button>
              )}
            </div>
          </form>

          {/* Social Auth Section (Only on Step 1) */}
          {step === 1 && (
            <>
              <div className="auth-divider">
                <span>Or sign up with</span>
              </div>

              <div className="social-login">
                {/* Google Button */}
                <button
                  className="social-btn google"
                  onClick={() => handleSocialLogin('Google')}
                  disabled={isLoading}
                >
                  <svg
                    className="social-icon google-icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                      fill="#4285F4"
                    />
                    <path
                      d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                      fill="#34A853"
                    />
                    <path
                      d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                      fill="#EA4335"
                    />
                  </svg>
                  Continue with Google
                </button>

                {/* Apple Button */}
                <button
                  className="social-btn apple"
                  onClick={() => handleSocialLogin('Apple')}
                  disabled={isLoading}
                >
                  <svg
                    className="social-icon apple-icon"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.569 12.6254C17.597 15.652 20.2179 16.6592 20.247 16.672C20.2248 16.743 19.8282 18.1073 18.8662 19.5166C18.0345 20.7339 17.1714 21.9434 15.8117 21.9697C14.4756 21.9959 14.046 21.1897 12.5185 21.1897C10.9909 21.1897 10.5126 21.9434 9.2515 21.9959C7.94475 22.0472 6.93949 20.6784 6.10156 19.4641C4.39942 16.9838 3.0894 12.4521 4.83474 9.39327C5.69eth2 7.87701 7.27458 6.90006 8.97672 6.87392C10.2609 6.84779 11.4789 7.70753 12.2857 7.70753C13.0913 7.70753 14.5477 6.68826 16.0737 6.87392C16.7408 6.90006 18.3937 7.1453 19.4753 8.65116C19.3795 8.70344 17.5461 9.85806 17.569 12.6254"
                      fill="currentColor"
                    />
                  </svg>
                  Continue with Apple
                </button>
              </div>
            </>
          )}

          {/* Footer */}
          <p className="auth-footer">
            Already have an account?{' '}
            <Link to="/login" className="auth-link">
              Sign in
            </Link>
          </p>
        </div>
      </div>

      {/* Toastify Container */}
      <ToastContainer />
    </div>
  );
};

export default SignupPage;
