import { useLayoutEffect, useCallback, useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { debounce } from 'lodash';

gsap.registerPlugin(ScrollTrigger);

const ANIMATION_TIMINGS = {
  INIT_DELAY: 100,
  RESIZE_DEBOUNCE: 100,
  SCROLL_DEBOUNCE: 0,
};

// Constants
const TYPING_CONFIG = {
  text: 'Craft a meditation script that gently leads me on a journey of identifying and releasing that which no longer serves me.',
};

const STAR_FIELD_CONFIG = [
  { count: 150, size: [0.5, 1.5], speed: 0.2, opacity: [0.3, 0.6], color: '#ffffff', zIndex: 1 },
  { count: 100, size: [1, 2], speed: 0.4, opacity: [0.4, 0.7], color: '#f0f0ff', zIndex: 2 },
  { count: 75, size: [1.5, 2.5], speed: 0.6, opacity: [0.5, 0.8], color: '#e0e0ff', zIndex: 3 },
];


const useGSAPAnimations = ({
  landingRef,
  howItWorksRef,
  mindfulnessRef,
  cognitiveRef,
  downloadRef,
  iphoneImgRef,
  headphoneRef,
  dynamicTextRef,
  auroraBgRef,
  splineContainerRef,
  audioFeaturesRef,
  intentTextRef,
  nextSectionRef,
  mindfulnessFeaturesRef,
  factTextRefs,
  calendarDaysRef,
  calendarHeaderRef,


}) => {
  const isMounted = useRef(true);
  const activeAnimations = useRef(new Set());
  const downloadElements = useRef({
    dliconContainer: null,
    title: null,
    storeButtons: null
  });

  const trackAnimation = useCallback((animation) => {
    activeAnimations.current.add(animation);
    return animation;
  }, []);

  const cleanupAnimations = useCallback(() => {
    const ctx = gsap.context(() => {
      ScrollTrigger.getAll().forEach(st => st.kill());
      ScrollTrigger.clearMatchMedia();
      activeAnimations.current.forEach(tween => tween.kill());
      activeAnimations.current.clear();
    });
    return () => ctx.revert();
  }, []);



  const handleScroll = useCallback(
    debounce(() => {
      if (!isMounted.current) return;
      ScrollTrigger.update();
    }, ANIMATION_TIMINGS.SCROLL_DEBOUNCE),
    []
  );

// Star field animation
const initializeStarField = useCallback(() => {
  if (!landingRef?.current || !isMounted.current) return;

  const existingStarField = landingRef.current.querySelector('.star-field-overlay');
  if (existingStarField) existingStarField.remove();

  const starField = document.createElement('div');
  starField.className = 'star-field-overlay';
  landingRef.current.appendChild(starField);

  STAR_FIELD_CONFIG.forEach((layerConfig, layerIndex) => {
    const layer = document.createElement('div');
    layer.className = `star-layer layer-${layerIndex}`;
    layer.style.zIndex = layerConfig.zIndex;
    starField.appendChild(layer);

    Array.from({ length: layerConfig.count }).forEach(() => {
      const star = document.createElement('div');
      star.className = 'star';
      const size = gsap.utils.random(layerConfig.size[0], layerConfig.size[1]);
      const opacity = gsap.utils.random(layerConfig.opacity[0], layerConfig.opacity[1]);

      Object.assign(star.style, {
        left: `${gsap.utils.random(0, 100)}%`,
        top: `${gsap.utils.random(0, 100)}%`,
        width: `${size}px`,
        height: `${size}px`,
        opacity,
        backgroundColor: layerConfig.color,
        boxShadow: `0 0 ${size * 2}px ${layerConfig.color}`,
      });

      layer.appendChild(star);

      trackAnimation(
        gsap.to(star, {
          opacity: opacity * 0.3,
          duration: gsap.utils.random(1, 3),
          repeat: -1,
          yoyo: true,
          ease: 'sine.inOut',
          delay: gsap.utils.random(0, 2),
        })
      );
    });

    trackAnimation(
      gsap.to(layer, {
        y: `${-30 * layerConfig.speed}%`,
        ease: 'none',
        scrollTrigger: {
          trigger: landingRef.current,
          start: 'top top',
          end: 'bottom top',
          scrub: 1.5,
          invalidateOnRefresh: true,
        },
      })
    );
  });
}, [landingRef, trackAnimation]);

// Landing parallax
const initializeLandingParallax = useCallback(() => {
  if (!landingRef?.current || !isMounted.current) return;

  const elements = {
    logo: landingRef.current.querySelector('.logo-container'),
    heading: landingRef.current.querySelector('h2'),
    background: landingRef.current.querySelector('.background-layer'),
  };

  gsap.set([elements.logo, elements.heading], { opacity: 1 });
  gsap.set(elements.background, { y: '0%' });

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: landingRef.current,
      start: 'top top',
      end: 'bottom top',
      scrub: 0.3,
      invalidateOnRefresh: true,
    },
  });

  if (elements.logo) {
    timeline.to(
      elements.logo,
      {
        y: -100,
        scale: 0.9,
        opacity: 0.6,
        duration: 0.5,
        ease: 'power2.out',
      },
      0
    );
  }

  if (elements.heading) {
    const words = elements.heading.textContent.trim().split(/\s+/);
    elements.heading.innerHTML = words
      .map((word, i) => `<span class="word word-${i}">${word}</span>`)
      .join(' ');

    timeline.to(
      elements.heading.querySelectorAll('.word'),
      {
        y: 30,
        opacity: 0,
        stagger: 0.05,
        ease: 'power2.out',
        duration: 0.5,
      },
      0
    );
  }

  if (elements.background) {
    timeline.to(
      elements.background,
      {
        y: '20%',
        duration: 0.5,
        ease: 'power2.out',
      },
      0
    );
  }

  trackAnimation(timeline);
}, [landingRef, trackAnimation]);

// Ripple animation
const initializeRippleAnimation = useCallback(() => {
  if (!howItWorksRef?.current || !isMounted.current) return;

  const container = howItWorksRef.current.querySelector('.pulsating-ripple-container');
  const ripples = Array.from(howItWorksRef.current.querySelectorAll('.ripple'));

  if (!container || !ripples.length) return;

  gsap.set([container, ripples], { opacity: 0 });

  const masterTimeline = gsap.timeline({
    onUpdate: updateRipples,
  });

  masterTimeline.to({}, { duration: 10 });

  const scrollTrigger = ScrollTrigger.create({
    trigger: howItWorksRef.current,
    start: 'top 70%',
    end: 'bottom 30%',
    scrub: true,
    onUpdate: updateOpacity,
    animation: masterTimeline,
    invalidateOnRefresh: true,
  });

  function updateRipples() {
    if (!isMounted.current) return;

    const progress = masterTimeline.time();
    ripples.forEach((ripple, index) => {
      const t = progress;
      const scale = 1 + 0.3 * Math.sin(t * 3 + index) * 
                   Math.sin(t * 2 + index * 1.5 + 1) * 
                   Math.sin(t * 1.5 + index * 2 + 2);

      gsap.set(ripple, {
        scale,
        transformOrigin: 'center center',
      });
    });
  }

  function updateOpacity(self) {
    if (!isMounted.current) return;

    const progress = self.progress;
    let opacity = progress < 0.2 
      ? gsap.utils.mapRange(0, 0.2, 0, 1, progress)
      : progress > 0.8
        ? gsap.utils.mapRange(0.8, 1, 1, 0, progress)
        : 1;

    gsap.set([container, ripples], { opacity });
  }

  trackAnimation(masterTimeline);

  return () => {
    scrollTrigger?.kill();
    masterTimeline?.kill();
  };
}, [howItWorksRef, trackAnimation, isMounted]);

// Scroll text animation
const initializeScrollText = useCallback(() => {
  if (!dynamicTextRef?.current || !howItWorksRef?.current || !isMounted.current) return;

  gsap.set(dynamicTextRef.current, { opacity: 1 });
  dynamicTextRef.current.innerHTML = '<span class="cursor"></span>';

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: howItWorksRef.current,
      start: 'top 60%',
      end: 'center center',
      scrub: 0.5,
      anticipatePin: 1,
      fastScrollEnd: true,
      preventOverlaps: true
    }
  });

  timeline.to({}, {
    duration: 1,
    onUpdate: function() {
      if (!dynamicTextRef.current || !isMounted.current) return;
      const progress = Math.floor(this.progress() * TYPING_CONFIG.text.length);
      const displayedText = TYPING_CONFIG.text.substring(0, progress);
      dynamicTextRef.current.innerHTML = `${displayedText}<span class="cursor"></span>`;
    }
  });

  trackAnimation(timeline);
}, [dynamicTextRef, howItWorksRef, trackAnimation]);

// Device animations
const initializeDeviceAnimations = useCallback(() => {
  if (!iphoneImgRef?.current || !headphoneRef?.current || !howItWorksRef?.current || !isMounted.current) return;

  gsap.set([iphoneImgRef.current, headphoneRef.current], {
    opacity: 1,
    scale: 1,
    rotation: 0,
    x: 0,
    y: 0,
  });

  const timeline = gsap.timeline({
    scrollTrigger: {
      trigger: howItWorksRef.current,
      start: 'top bottom',
      end: 'bottom top',
      scrub: 1,
      invalidateOnRefresh: true,
    },
  });

  timeline
    .to(iphoneImgRef.current, {
      yPercent: 80,
      xPercent: 20,
      rotate: 15,
      ease: 'power3.out',
    }, 0)
    .to(headphoneRef.current, {
      yPercent: -80,
      xPercent: -20,
      rotate: -15,
      ease: 'power3.out',
    }, 0);

  trackAnimation(timeline);
}, [iphoneImgRef, headphoneRef, howItWorksRef, trackAnimation]);

const initializeCalendarAnimation = useCallback(() => {
  if (!calendarDaysRef?.current) return;
  
  // All calendar days
  const days = calendarDaysRef.current.children;
  // Calendar header
  const header = calendarHeaderRef.current;
  // Week day labels
  const weekDays = document.querySelectorAll('.calendar-week-day div');
  
  // Set initial states
  gsap.set([header, weekDays], {
    opacity: 0,
    y: -20
  });
  
  gsap.set(days, {
    opacity: 0,  
    scale: 0.8,
    y: 20
  });

  // Create timeline
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: calendarDaysRef.current,
      start: "top 100%",
      toggleActions: "play none none reverse"
    }
  });

  // Animate header
  tl.to(header, {
    opacity: 1,
    y: 0,
    duration: 0.4,
    ease: "power2.out"
  })
  // Animate weekday labels
  .to(weekDays, {
    opacity: 1,
    y: 0,
    duration: 0.3,
    stagger: 0.05,
    ease: "power2.out"
  })
  // Animate calendar days
  .to(days, {
    opacity: 1,
    scale: 1,
    y: 0,
    duration: 0.3,
    stagger: {
      grid: [7, 6], // 7 columns, 6 rows
      from: "start",
      amount: 0.8
    },
    ease: "back.out(1.2)"
  });

}, [calendarDaysRef, calendarHeaderRef]);

// Features animation
const initializeFeaturesAnimation = useCallback(() => {
  if (!audioFeaturesRef?.current || !isMounted.current) return;

  const features = Array.from(audioFeaturesRef.current.children);
  if (!features.length) return;

  gsap.set(features, { opacity: 0, y: 20 });

  features.forEach((feature, index) => {
    trackAnimation(
      gsap.fromTo(
        feature,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.6,
          delay: index * 0.1,
          ease: 'power2.out',
          scrollTrigger: {
            trigger: feature,
            start: 'top 80%',
            toggleActions: 'play none none none',
            invalidateOnRefresh: true,
          },
        }
      )
    );
  });
}, [audioFeaturesRef, trackAnimation]);

// Aurora effect
const initializeAuroraEffect = useCallback(() => {
  if (!mindfulnessRef?.current || !auroraBgRef?.current || !isMounted.current) return;

  const elements = {
    mindfulness: mindfulnessRef.current,
    aurora: auroraBgRef.current,
  };

  gsap.set(elements.aurora, { opacity: 0, y: 0 });

  const scrollTrigger = ScrollTrigger.create({
    trigger: elements.mindfulness,
    start: 'top bottom',
    end: 'bottom top',
    onUpdate: (self) => {
      if (!isMounted.current) return;
      const opacity = self.progress > 0.1 && self.progress < 0.9
        ? 1
        : self.progress <= 0.1
          ? self.progress * 10
          : (1 - self.progress) * 10;

      gsap.to(elements.aurora, {
        opacity,
        duration: 0.5,
        overwrite: 'auto',
      });
    },
    invalidateOnRefresh: true,
  });

  const handleMove = (x, y, elements) => {
    if (!isMounted.current) return;
    const viewportHeight = window.innerHeight;
    const scrollOffset = window.scrollY - elements.mindfulness.offsetTop;

    gsap.to(elements.aurora, {
      '--mouse-x': `${(x / window.innerWidth) * 100}%`,
      '--mouse-y': `${((y + scrollOffset) / (viewportHeight * 2)) * 100}%`,
      duration: 1,
      ease: 'power2.out',
      overwrite: 'auto',
    });
  };

  const handlers = {
    mouse: (e) => handleMove(e.clientX, e.clientY, elements),
    touch: (e) => e.touches[0] && handleMove(e.touches[0].clientX, e.touches[0].clientY, elements),
  };

  elements.mindfulness.addEventListener('mousemove', handlers.mouse);
  elements.mindfulness.addEventListener('touchmove', handlers.touch);

  trackAnimation(scrollTrigger);

  return () => {
    elements.mindfulness.removeEventListener('mousemove', handlers.mouse);
    elements.mindfulness.removeEventListener('touchmove', handlers.touch);
    scrollTrigger.kill();
  };
}, [mindfulnessRef, auroraBgRef, trackAnimation]);

const initializeSplineTransitions = useCallback(() => {
  if (!mindfulnessRef?.current || !splineContainerRef?.current || !isMounted.current) return;

  const spline = splineContainerRef.current.querySelector('canvas');
  if (!spline) return;

  const setCanvasStyles = () => {
    if (spline && isMounted.current) {
      spline.style.mixBlendMode = 'screen';
      spline.style.backgroundColor = 'transparent';
      spline.style.aspectRatio = '1/1';
      spline.style.objectFit = 'contain';
    }
  };

  setCanvasStyles();

  const splineTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: mindfulnessRef.current,
      start: 'top bottom',
      end: 'top center',
      scrub: 1,
      invalidateOnRefresh: true,
      onUpdate: setCanvasStyles,
    },
  });

  gsap.set(spline, {
    opacity: 1,
    y: 0,
    filter: 'blur(0px)'
  });

  splineTimeline.to(
    spline,
    {
      opacity: 0,
      y: -50,
      filter: 'blur(20px)',
      duration: 1,
      ease: 'power2.out',
    }
  );

  const observer = new MutationObserver(setCanvasStyles);
  observer.observe(splineContainerRef.current, {
    attributes: true,
    childList: true,
    subtree: true,
  });

  trackAnimation(splineTimeline);
  return () => observer.disconnect();
}, [mindfulnessRef, splineContainerRef, trackAnimation]);

const initializeIntentTextAnimation = useCallback(() => {
  if (!intentTextRef?.current || !howItWorksRef?.current || !isMounted.current) return;

  gsap.set(intentTextRef.current, { opacity: 0, y: 20 });

  const intentAnimation = gsap.fromTo(
    intentTextRef.current,
    { opacity: 0, y: 20 },
    {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: 'power2.out',
      scrollTrigger: {
        trigger: howItWorksRef.current,
        start: 'top center',
        end: 'bottom center',
        scrub: false,
        toggleActions: 'play none none none',
        invalidateOnRefresh: true,
      },
    }
  );

  trackAnimation(intentAnimation);
}, [intentTextRef, howItWorksRef, trackAnimation]);

const initializeSectionTransitions = useCallback(() => {
  if (!isMounted.current) return;

  // How it Works section animation
  if (howItWorksRef?.current) {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: howItWorksRef.current,
        start: 'top center+=100',
        end: 'bottom center',
        toggleActions: 'play reverse restart reverse',
        scrub: 0.5
      }
    });

    timeline
      .fromTo(intentTextRef.current, 
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 0.8 }
      )
      .fromTo(dynamicTextRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.6 },
        '-=0.4'
      );
  }

  // Benefits section animation
  if (splineContainerRef?.current) {
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: splineContainerRef.current,
        start: 'top bottom-=100',
        end: 'bottom top+=100',
        toggleActions: 'play reverse restart reverse',
        scrub: 0.5
      }
    });

    timeline.fromTo(splineContainerRef.current,
      { opacity: 0, scale: 0.95 },
      { opacity: 1, scale: 1, duration: 1 }
    );
  }

  // Mindfulness features animation
  if (mindfulnessFeaturesRef?.current) {
    const features = mindfulnessFeaturesRef.current.querySelectorAll('.mindfulness-feature');
    
    features.forEach((feature, index) => {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: feature,
          start: 'top bottom-=100',
          end: 'bottom top+=100',
          toggleActions: 'play reverse restart reverse',
          scrub: 0.5
        }
      });

      timeline.fromTo(feature,
        { opacity: 0, y: 50, scale: 0.95 },
        { 
          opacity: 1, 
          y: 0, 
          scale: 1,
          duration: 0.6,
          delay: index * 0.2
        }
      );
    });
  }

  // Cognitive section animation
  if (cognitiveRef?.current) {
    const elements = {
      splineContainer: cognitiveRef.current.querySelector('.spline2-container'),
      titleContainer: cognitiveRef.current.querySelector('.title-container'),
      facts: cognitiveRef.current.querySelectorAll('.fact'),
      cta: cognitiveRef.current.querySelector('.cta-button')
    };

    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: cognitiveRef.current,
        start: 'top bottom-=100',
        end: 'bottom center',
        toggleActions: 'play reverse restart reverse',
        scrub: 0.5
      }
    });

    timeline
      .fromTo([elements.splineContainer, elements.titleContainer],
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 0.8, stagger: 0.2 }
      )
      .fromTo(elements.facts,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 0.6, stagger: 0.15 },
        '-=0.4'
      )
      .fromTo(elements.cta,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 0.6 },
        '-=0.2'
      );
  }


  
  if (downloadRef?.current) {
    const elements = {
      container: downloadRef.current.querySelector('.dlicon-container'),
      title: downloadRef.current.querySelector('h2'),
      storeButtons: downloadRef.current.querySelector('.store-buttons'),
      storeLinks: [...downloadRef.current.querySelectorAll('.store-link')]
    };
  
    if (elements.container && elements.title && elements.storeButtons) {
      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: downloadRef.current,
          start: "top 70%",
          end: "center center",
          toggleActions: "play none none reverse",
        }
      });
  
      timeline
        .fromTo(elements.container,
          { 
            opacity: 0,
            scale: 0.4,  // Start at a smaller scale
            y: 100,
            duration: 1.2,
            ease: "elastic.out(1, 0.5)"
          },
          { 
            opacity: 1,
            scale: 1.25,  // End at 125% size
            y: 0,
            duration: 1.2,
            ease: "elastic.out(1, 0.5)"
          }
        )
        .fromTo(elements.title,
          { 
            opacity: 0, 
            y: 50,
            scale: 0.8 
          },
          { 
            opacity: 1, 
            y: 0,
            scale: 1,
            duration: 0.8,
            ease: "back.out(1.7)" 
          },
          "-=0.8"
        )
        .fromTo(elements.storeButtons,
          { 
            opacity: 0, 
            y: 50,
            scale: 0.8 
          },
          { 
            opacity: 1, 
            y: 0,
            scale: 1,
            duration: 0.8,
            ease: "back.out(1.7)" 
          },
          "-=0.6"
        )
        .fromTo(elements.storeLinks,
          { 
            opacity: 0, 
            y: 30,
            scale: 0.8 
          },
          { 
            opacity: 1,
            y: 0,
            scale: 1,
            stagger: 0.15,
            duration: 0.6,
            ease: "back.out(2)"
          },
          "-=0.4"
        );
  
      trackAnimation(timeline);
    }
  }
}, [howItWorksRef, splineContainerRef, mindfulnessFeaturesRef, cognitiveRef, downloadRef, trackAnimation, isMounted]);

const initializeGradientLineAnimation = useCallback(() => {
  if (!factTextRefs?.current || !isMounted.current) return;

  factTextRefs.current.forEach((fact) => {
    if (!fact) return;

    const gradientLine = fact.querySelector('.gradient-line');
    if (!gradientLine) return;

    gsap.set(gradientLine, {
      scaleX: 0,
      transformOrigin: 'left center',
    });

    const hoverTimeline = gsap.timeline({ paused: true });
    hoverTimeline.to(gradientLine, {
      scaleX: 1,
      duration: 0.6,
      ease: 'power2.out',
    });

    fact.addEventListener('mouseenter', () => hoverTimeline.play());
    fact.addEventListener('mouseleave', () => hoverTimeline.reverse());
  });
}, [factTextRefs, isMounted]);

const initializeGradientTextAnimation = useCallback(() => {
  if (!factTextRefs?.current || !isMounted.current) return;

  factTextRefs.current.forEach((fact) => {
    const title = fact.querySelector('.fact-title');
    const description = fact.querySelector('.fact-description');

    [title, description].forEach((textEl) => {
      if (!textEl) return;

      gsap.set(textEl, {
        backgroundImage: 'linear-gradient(90deg, #bb86fc, #3700b3)',
        backgroundSize: '200% 200%',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        backgroundPosition: '0% 50%',
        opacity: 1,
      });

      const hoverAnimation = gsap.timeline({ paused: true });
      hoverAnimation.to(textEl, {
        backgroundPosition: '100% 50%',
        duration: 0.8,
        ease: 'power1.inOut',
      });

      textEl.addEventListener('mouseenter', () => hoverAnimation.play());
      textEl.addEventListener('mouseleave', () => hoverAnimation.reverse());
    });
  });
}, [factTextRefs, isMounted]);

useEffect(() => {
  initializeGradientLineAnimation();
  initializeGradientTextAnimation();
}, [initializeGradientLineAnimation, initializeGradientTextAnimation]);



const initializeNextSectionIndicator = useCallback(() => {
  if (!nextSectionRef?.current || !isMounted.current) return;

  const indicatorAnimation = gsap.to(nextSectionRef.current, {
    y: 10,
    duration: 1.5,
    repeat: -1,
    yoyo: true,
    ease: 'power1.inOut',
  });

  trackAnimation(indicatorAnimation);
}, [nextSectionRef, trackAnimation]);


const initializeBrainAnimation = () => {
  const brainGif = document.querySelector('.fact-gif2');
  
  if (!brainGif) return;

  // Set initial state
  gsap.set(brainGif, { 
    opacity: 0,
    y: 45
  });

  // Create scroll trigger animation
  gsap.timeline({
    scrollTrigger: {
      trigger: brainGif,
      start: 'top 80%',
      end: 'top 20%',
      toggleActions: 'play none none reverse',
      onEnter: () => brainGif.classList.add('animate'),
      onLeave: () => brainGif.classList.remove('animate'),
      onEnterBack: () => brainGif.classList.add('animate'),
      onLeaveBack: () => brainGif.classList.remove('animate')
    }
  })
  .to(brainGif, {
    opacity: 1,
    y: 35,
    duration: 1,
    ease: 'power2.out'
  });
};


// After all initializations
setTimeout(() => {
  ScrollTrigger.refresh(true);
}, 100);

useLayoutEffect(() => {
  isMounted.current = true;
  cleanupAnimations();

  const initializeAnimations = () => {
    if (!isMounted.current) return;

    const ctx = gsap.context(() => {
      try {
        setTimeout(() => {
          initializeStarField();
          initializeNextSectionIndicator();
          initializeIntentTextAnimation();
          initializeLandingParallax();
          initializeRippleAnimation();
          initializeScrollText();
          initializeDeviceAnimations();
          initializeFeaturesAnimation();
          initializeAuroraEffect();
          initializeSplineTransitions();
          initializeSectionTransitions();
          initializeGradientTextAnimation();
          initializeGradientLineAnimation();
          initializeCalendarAnimation();
          initializeBrainAnimation();

          
          ScrollTrigger.refresh(true);
        }, ANIMATION_TIMINGS.INIT_DELAY);
      } catch (error) {
        console.error('Error initializing animations:', error);
        initializeStarField();
        initializeLandingParallax();
      }
    });

    return () => ctx.revert();
  };

  requestAnimationFrame(initializeAnimations);

  window.addEventListener('scroll', handleScroll, { passive: true });

  return () => {
    isMounted.current = false;
    window.removeEventListener('scroll', handleScroll);
    cleanupAnimations();
    };
  }, [


  cleanupAnimations,
  handleScroll,
  initializeStarField,
  initializeNextSectionIndicator,
  initializeIntentTextAnimation,
  initializeLandingParallax,
  initializeRippleAnimation,
  initializeScrollText,
  initializeDeviceAnimations,
  initializeFeaturesAnimation,
  initializeAuroraEffect,
  initializeSplineTransitions,
  initializeSectionTransitions,
  initializeGradientTextAnimation,
  initializeGradientLineAnimation,
  

]);

useEffect(() => {
  const handleResize = debounce(() => {
    if (!isMounted.current) return;
    try {
      ScrollTrigger.refresh();
    } catch (error) {
      console.error('Error refreshing ScrollTrigger:', error);
    }
  }, ANIMATION_TIMINGS.RESIZE_DEBOUNCE);

  window.addEventListener('resize', handleResize);
  return () => {
    handleResize.cancel();
    window.removeEventListener('resize', handleResize);
  };
}, []);

return {
  refresh: useCallback(() => {
    if (!isMounted.current) return;
    ScrollTrigger.refresh();
  }, []),
};
};

export default useGSAPAnimations;


