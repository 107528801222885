import React, { useState } from 'react';
import { Bell, Check, X, AlertTriangle, Info, Clock } from 'lucide-react';
import './NotificationsPage.css';

const NotificationsPage = () => {
  const [filter, setFilter] = useState('all');
  const [notifications, setNotifications] = useState([
    {
      id: 1,
      type: 'success',
      title: 'Meditation Streak!',
      message: 'You\'ve completed 7 days of meditation in a row.',
      timestamp: new Date(Date.now() - 1000 * 60 * 30),
      read: false
    },
    {
      id: 2,
      type: 'info',
      title: 'New Feature Available',
      message: 'Try our new guided breathing exercises in your next session.',
      timestamp: new Date(Date.now() - 1000 * 60 * 60 * 2),
      read: false
    },
    {
      id: 3,
      type: 'reminder',
      title: 'Evening Session Reminder',
      message: 'It\'s time for your scheduled meditation session.',
      timestamp: new Date(Date.now() - 1000 * 60 * 60 * 4),
      read: true
    }
  ]);

  const formatTimestamp = (timestamp) => {
    const now = new Date();
    const diffInMinutes = Math.floor((now - timestamp) / 60000);

    if (diffInMinutes < 1) return 'Just now';
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    if (diffInMinutes < 1440) return `${Math.floor(diffInMinutes / 60)}h ago`;
    return timestamp.toLocaleDateString();
  };

  const getIconComponent = (type) => {
    switch (type) {
      case 'success':
        return <Check className="notification-icon success" />;
      case 'error':
        return <AlertTriangle className="notification-icon error" />;
      case 'info':
        return <Info className="notification-icon info" />;
      case 'reminder':
        return <Clock className="notification-icon reminder" />;
      default:
        return <Bell className="notification-icon default" />;
    }
  };

  const handleMarkAsRead = (id) => {
    setNotifications(prev =>
      prev.map(notif =>
        notif.id === id ? { ...notif, read: true } : notif
      )
    );
  };

  const handleClear = (id) => {
    setNotifications(prev => prev.filter(notif => notif.id !== id));
  };

  const handleClearAll = () => {
    setNotifications([]);
  };

  const filteredNotifications = notifications.filter(notif => {
    if (filter === 'unread') return !notif.read;
    if (filter === 'read') return notif.read;
    return true;
  });

  return (
    <div className="notifications-page">
      <div className="filter-group">
        <button 
          className={`filter-btn ${filter === 'all' ? 'active' : ''}`}
          onClick={() => setFilter('all')}
        >
          All
        </button>
        <button 
          className={`filter-btn ${filter === 'unread' ? 'active' : ''}`}
          onClick={() => setFilter('unread')}
        >
          Unread
        </button>
        <button 
          className={`filter-btn ${filter === 'read' ? 'active' : ''}`}
          onClick={() => setFilter('read')}
        >
          Read
        </button>
      </div>

      <div className="notifications-list">
        {filteredNotifications.length > 0 ? (
          filteredNotifications.map((notification) => (
            <div 
              key={notification.id}
              className={`notification-item ${notification.read ? 'read' : ''}`}
            >
              {getIconComponent(notification.type)}
              <div className="notification-content">
                <h3>{notification.title}</h3>
                <p>{notification.message}</p>
                <time>{formatTimestamp(notification.timestamp)}</time>
              </div>
              <div className="notification-actions">
                {!notification.read && (
                  <button
                    onClick={() => handleMarkAsRead(notification.id)}
                    className="action-btn mark-read"
                    aria-label="Mark as read"
                  >
                    <Check size={20} />
                  </button>
                )}
                <button
                  onClick={() => handleClear(notification.id)}
                  className="action-btn clear"
                  aria-label="Remove notification"
                >
                  <X size={20} />
                </button>
              </div>
            </div>
          ))
        ) : (
          <div className="empty-state">
            <Bell size={32} />
            <p>No notifications to display</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default NotificationsPage;