import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import gsap from 'gsap';

// Define mood options
export const MOOD_OPTIONS = {
  Happy: { emoji: '😊', color: '#FFD93D' },
  Excited: { emoji: '🤩', color: '#FF8400' },
  Peaceful: { emoji: '😌', color: '#6BCB77' },
  Neutral: { emoji: '😐', color: '#89CFF0' },
  Tired: { emoji: '😴', color: '#B983FF' },
  Sad: { emoji: '😢', color: '#4D96FF' },
  Angry: { emoji: '😠', color: '#FF6B6B' },
  None: { emoji: '❌', color: '#FF1700' },
};

// Define month names
export const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

// Define days of the week
export const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const useCalendar = () => {
  const today = useMemo(() => {
    const date = new Date();
    date.setHours(0, 0, 0, 0);
    return date;
  }, []);

  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [showMoodSelector, setShowMoodSelector] = useState(false);
  const [activeCell, setActiveCell] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [loadingState, setLoadingState] = useState('loading');
  const [moods, setMoods] = useState({});
  const dayRefs = useRef({});
  const moodSelectorRef = useRef(null);

  // Utility to check if the date is in the past
  const isDateInPast = useCallback((date) => {
    const compareDate = new Date(date);
    compareDate.setHours(0, 0, 0, 0);
    return compareDate <= today;
  }, [today]);

  // Generate a random mood (excluding "None")
  const generateRandomMood = useCallback(() => {
    const moodKeys = Object.keys(MOOD_OPTIONS).filter((key) => key !== 'None');
    return moodKeys[Math.floor(Math.random() * moodKeys.length)];
  }, []);

  // Initialize moods
  useEffect(() => {
    try {
      const savedMoods = localStorage.getItem('calendarMoods');
      const validatedMoods = {};
      if (savedMoods) {
        const parsedMoods = JSON.parse(savedMoods);
        Object.entries(parsedMoods).forEach(([dateKey, mood]) => {
          if (MOOD_OPTIONS[mood]) validatedMoods[dateKey] = mood;
        });
      }

      const startDate = new Date(today.getFullYear(), 0, 1);
      while (startDate <= today) {
        const dateKey = startDate.toISOString().split('T')[0];
        if (!validatedMoods[dateKey]) {
          validatedMoods[dateKey] = generateRandomMood();
        }
        startDate.setDate(startDate.getDate() + 1);
      }
      setMoods(validatedMoods);
      setLoadingState('success');
    } catch (error) {
      console.error('Error initializing moods:', error);
      setLoadingState('error');
    }
  }, [today, generateRandomMood]);

  // Save moods to localStorage whenever moods state changes
  useEffect(() => {
    if (loadingState === 'success') {
      try {
        localStorage.setItem('calendarMoods', JSON.stringify(moods));
      } catch (error) {
        console.error('Error saving moods:', error);
      }
    }
  }, [moods, loadingState]);

  // Close mood selector on clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (moodSelectorRef.current && !moodSelectorRef.current.contains(event.target)) {
        setShowMoodSelector(false);
        setActiveCell(null);
      }
    };

    if (showMoodSelector) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMoodSelector]);

const handleDayClick = useCallback((date, dateKey, isPast, event) => {
  if (!isPast) return;
  event.preventDefault();
  event.stopPropagation();

  // Create a new date object and adjust for timezone
  const selectedDate = new Date(date);
  selectedDate.setMinutes(selectedDate.getMinutes() + selectedDate.getTimezoneOffset());
  
  setActiveCell(selectedDate.toISOString().split('T')[0]);
  setShowMoodSelector(true);

  const cell = dayRefs.current[dateKey];
  if (cell) {
    gsap.to(cell, {
      scale: 1.05,
      duration: 0.2,
      ease: 'back.out(1.7)',
    });
  }
}, []);


  const handleMoodSelect = useCallback((mood, dateKey) => {
    if (!dateKey) return;

    setMoods((prevMoods) => {
      const updatedMoods = { ...prevMoods };
      if (mood === 'None') {
        delete updatedMoods[dateKey];
      } else {
        updatedMoods[dateKey] = mood;
      }
      return updatedMoods;
    });

    const cell = dayRefs.current[dateKey];
    if (cell) {
      gsap.timeline()
        .to(cell, { scale: 1.1, duration: 0.2, ease: 'back.out(1.7)' })
        .to(cell, { scale: 1, duration: 0.2, ease: 'power2.out', clearProps: 'transform' });
    }

    gsap.to(moodSelectorRef.current, {
      opacity: 0,
      y: -10,
      duration: 0.2,
      ease: 'power2.in',
      onComplete: () => {
        setShowMoodSelector(false);
        setActiveCell(null);
      },
    });
  }, []);

  const generateCalendarDays = useCallback(() => {
    const days = [];
    const firstDay = new Date(currentYear, currentMonth, 1).getDay();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const prevMonthDays = new Date(currentYear, currentMonth, 0).getDate();
  
    // Previous month days (inactive)
    for (let i = firstDay - 1; i >= 0; i--) {
      const day = prevMonthDays - i;
      days.push(
        <div key={`prev-${day}`} className="calendar-day calendar-day-inactive">
          <div className="day-content">
            <span className="day-number">{day}</span>
          </div>
        </div>
      );
    }
  
    // Current month days
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day);
      const dateKey = date.toISOString().split('T')[0];
      const isToday = date.getTime() === today.getTime();
      const isPast = isDateInPast(date);
      const mood = moods[dateKey];
  
      days.push(
        <div
          key={dateKey}
          ref={(el) => {
            if (el) dayRefs.current[dateKey] = el;
            else delete dayRefs.current[dateKey];
          }}
          className={`calendar-day 
            ${isToday ? 'current-day' : ''} 
            ${mood ? 'has-mood' : ''} 
            ${activeCell === dateKey ? 'active' : ''} 
            ${isPast ? 'past clickable' : 'future'}`}
          onClick={isPast ? (e) => handleDayClick(date, dateKey, isPast, e) : undefined}
        >
          <div className="day-content">
            <span className="day-number">{day}</span>
            {mood && (
              <span
                className="mood-emoji"
                style={{ color: MOOD_OPTIONS[mood]?.color }}
              >
                {MOOD_OPTIONS[mood]?.emoji}
              </span>
            )}
          </div>
        </div>
      );
    }
  
    // Next month days (inactive)
    const totalCells = Math.ceil((daysInMonth + firstDay) / 7) * 7;
    const nextDays = totalCells - (daysInMonth + firstDay);
    for (let i = 1; i <= nextDays; i++) {
      days.push(
        <div key={`next-${i}`} className="calendar-day calendar-day-inactive">
          <div className="day-content">
            <span className="day-number">{i}</span>
          </div>
        </div>
      );
    }
  
    return days;
  }, [currentYear, currentMonth, moods, activeCell, handleDayClick, isDateInPast, today]);
  
  

  return {
    dayRefs,
    currentYear,
    currentMonth,
    showMoodSelector,
    activeCell,
    moods,
    isAnimating,
    moodSelectorRef,
    handleMoodSelect,
    generateCalendarDays,
    setShowMoodSelector,
    setActiveCell,
    DAYS,
    MOOD_OPTIONS,
  };
};

export default useCalendar;
