import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Lock } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { loginSchema } from '../../utils/validationSchemas';
import logo from '../../assets/logo_transparent.png';
import './Login.css';

const Logo = () => (
  <div className="logo-container">
    <img src={logo} alt="Pause Logo" className="logo" />
  </div>
);

const LoginPage = () => {
  const navigate = useNavigate();
  const { login, loginWithGoogle, loginWithApple } = useAuth();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (error) setError('');
  };

  const validateAndGetErrorMessage = async (schema, data) => {
    try {
      await schema.validate(data, { abortEarly: false });
      return null;
    } catch (err) {
      return err.errors[0];
    }
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const errorMessage = await validateAndGetErrorMessage(
        loginSchema.pick(['email']),
        { email: formData.email }
      );
      
      if (errorMessage) {
        setError(errorMessage);
        return;
      }

      setStep(2);
    } catch (err) {
      setError('Failed to process email. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const errorMessage = await validateAndGetErrorMessage(
        loginSchema,
        formData
      );
      
      if (errorMessage) {
        setError(errorMessage);
        return;
      }

      // Login and redirect to dashboard
      await login(formData.email, formData.password);
      navigate('/app'); // This now points to the dashboard
    } catch (err) {
      setError(
        err.message || 'Failed to sign in. Please check your credentials and try again.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  

  const handleSocialLogin = async (provider) => {
    setIsLoading(true);
    try {
      if (provider === 'google') {
        await loginWithGoogle();
      } else {
        await loginWithApple();
      }
      navigate('/app'); // Updated to point to dashboard
    } catch (err) {
      setError(`${provider} sign in failed. Please try again.`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-page">
  <div className="left-side">
    <div className="content">
      <div className="logo-container">
        <Link to="/">
          <img src={logo} alt="Pause Logo" className="logo" />
        </Link>
      </div>
    </div>
    <div className="star-field-overlay">
      <div className="star-layer" />
    </div>
  </div>
      
      <div className="right-side">
        <div className="login-card">
          <h2>{step === 1 ? 'Sign in to your account' : 'Enter your password'}</h2>
          <p>
            {step === 1 
              ? 'Enter your email below to sign in' 
              : `Welcome back! Please enter your password for ${formData.email}`
            }
          </p>

          {error && <div className="error-message">{error}</div>}
          
          <form onSubmit={step === 1 ? handleEmailSubmit : handleLogin}>
            {step === 1 ? (
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="name@example.com"
                  className="form-input"
                  disabled={isLoading}
                />
              </div>
            ) : (
              <div className="form-group">
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  className="form-input"
                  disabled={isLoading}
                />
              </div>
            )}
            
            <button 
              type="submit" 
              className={`login-btn ${isLoading ? 'loading' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? (
                <div className="loader">
                  <div className="loader-circle" />
                </div>
              ) : (
                <>
                  <Lock className="w-4 h-4" />
                  {step === 1 ? 'Continue with Email' : 'Sign In'}
                </>
              )}
            </button>
          </form>
          
          {step === 1 && (
            <>
              <div className="divider">OR CONTINUE WITH</div>
              
              <div className="social-login">
                <button 
                  className="social-btn google"
                  onClick={() => handleSocialLogin('google')}
                  disabled={isLoading}
                >
                  <svg className="social-icon google-icon" viewBox="0 0 24 24">
                    <path d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z" fill="#4285F4"/>
                    <path d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z" fill="#34A853"/>
                    <path d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z" fill="#FBBC05"/>
                    <path d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z" fill="#EA4335"/>
                  </svg>
                  Continue with Google
                </button>
                <button 
                  className="social-btn apple"
                  onClick={() => handleSocialLogin('apple')}
                  disabled={isLoading}
                >
                  <svg className="social-icon apple-icon" viewBox="0 0 24 24">
                    <path d="M17.569 12.6254C17.597 15.652 20.2179 16.6592 20.247 16.672C20.2248 16.743 19.8282 18.1073 18.8662 19.5166C18.0345 20.7339 17.1714 21.9434 15.8117 21.9697C14.4756 21.9959 14.046 21.1897 12.5185 21.1897C10.9909 21.1897 10.5126 21.9434 9.2515 21.9959C7.94475 22.0472 6.93949 20.6784 6.10156 19.4641C4.39942 16.9838 3.0894 12.4521 4.83474 9.39327C5.69eth2 7.87701 7.27458 6.90006 8.97672 6.87392C10.2609 6.84779 11.4789 7.70753 12.2857 7.70753C13.0913 7.70753 14.5477 6.68826 16.0737 6.87392C16.7408 6.90006 18.3937 7.1453 19.4753 8.65116C19.3795 8.70344 17.5461 9.85806 17.569 12.6254" fill="currentColor"/>
                  </svg>
                  Continue with Apple
                </button>
              </div>
            </>
          )}
          
          <p className="terms">
            {step === 1 ? (
              <>
                Don't have an account?{' '}
                <Link to="/signup" className="terms-link">
                  Sign up
                </Link>
              </>
            ) : (
              <button 
                onClick={() => setStep(1)} 
                className="back-link"
              >
                Use a different account
              </button>
            )}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;