import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Bell, Check, X, AlertTriangle, Info } from 'lucide-react';
import './notifications.css';

const panelAnimation = {
  initial: { opacity: 0, y: -20, scale: 0.95 },
  animate: { opacity: 1, y: 0, scale: 1 },
  exit: { opacity: 0, y: -20, scale: 0.95 },
  transition: { duration: 0.2 }
};

const getIconComponent = (type) => {
  const iconProps = { size: 16 };
  
  switch (type) {
    case 'success':
      return <Check {...iconProps} className="success-icon" />;
    case 'error':
      return <X {...iconProps} className="error-icon" />;
    case 'warning':
      return <AlertTriangle {...iconProps} className="warning-icon" />;
    case 'info':
    default:
      return <Info {...iconProps} className="info-icon" />;
  }
};

const formatTimestamp = (timestamp) => {
  try {
    const date = new Date(timestamp);
    const now = new Date();
    
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const diffInMs = now - date;
    const diffInSecs = Math.floor(diffInMs / 1000);
    const diffInMins = Math.floor(diffInSecs / 60);
    const diffInHours = Math.floor(diffInMins / 60);
    const diffInDays = Math.floor(diffInHours / 24);

    if (diffInSecs < 60) return 'Just now';
    if (diffInMins < 60) return `${diffInMins}m ago`;
    if (diffInHours < 24) return `${diffInHours}h ago`;
    if (diffInDays < 7) return `${diffInDays}d ago`;

    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: date.getFullYear() !== now.getFullYear() ? 'numeric' : undefined
    }).format(date);
  } catch {
    return 'Invalid Date';
  }
};

const NotificationItem = ({ notification, onMarkAsRead, onClear }) => (
  <motion.div
    initial={{ opacity: 0, y: -10 }}
    animate={{ opacity: 1, y: 0 }}
    className={`notification-item ${notification.read ? 'read' : ''}`}
  >
    <div className="notification-content">
      <span className="notification-icon">
        {getIconComponent(notification.type)}
      </span>
      
      <div className="notification-text">
        <h4>{notification.title}</h4>
        <p>{notification.message}</p>
        <time>{formatTimestamp(notification.timestamp)}</time>
      </div>

      <div className="notification-actions">
        {!notification.read && (
          <button
            onClick={() => onMarkAsRead?.(notification.id)}
            className="action-btn mark-read"
            aria-label="Mark as read"
          >
            <Check size={16} />
          </button>
        )}
        <button
          onClick={() => onClear?.(notification.id)}
          className="action-btn clear"
          aria-label="Remove notification"
        >
          <X size={16} />
        </button>
      </div>
    </div>
  </motion.div>
);

const EmptyState = () => (
  <div className="empty-state">
    <Bell size={24} />
    <p>No notifications</p>
  </div>
);

const NotificationsPanel = ({
  notifications = [],
  onMarkAsRead,
  onClear,
  onClearAll,
  onCloseNotifications,
  isNotificationsOpen,
  setIsThemeCustomizerOpen  // Added this prop
}) => {
  // Close theme customizer when notifications panel opens
  useEffect(() => {
    if (isNotificationsOpen) {
      setIsThemeCustomizerOpen(false);
    }
  }, [isNotificationsOpen, setIsThemeCustomizerOpen]);

  return (
    <motion.div
      className="notifications-panel"
      {...panelAnimation}
    >
      <div className="notifications-header">
        <h3>Notifications</h3>
        <div className="header-actions">
          {notifications.length > 0 && (
            <button
              onClick={onClearAll}
              className="clear-all-btn"
            >
              Clear All
            </button>
          )}
          <button
            onClick={onCloseNotifications}
            className="close-btn"
            aria-label="Close notifications"
          >
            <X size={16} />
          </button>
        </div>
      </div>

      <div className="notifications-content">
        {notifications.length > 0 ? (
          notifications.map((notification) => (
            <NotificationItem
              key={notification.id}
              notification={notification}
              onMarkAsRead={onMarkAsRead}
              onClear={onClear}
            />
          ))
        ) : (
          <EmptyState />
        )}
      </div>
    </motion.div>
  );
};

export default NotificationsPanel;