import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useResponsive } from '../../../hooks/useResponsive';
import './MusicLibraryDropdown.css';

const MusicLibraryDropdown = ({ onMusicSelect, isTTSLoaded, isSessionCreated }) => {
  const [musicFiles, setMusicFiles] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);
  const { isMobile, isTablet } = useResponsive();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  // Add getFontSize function
  const getFontSize = () => {
    if (isMobile) return '12px';
    if (isTablet) return '14px';
    return '16px';
  };

  const fetchMusicIndex = useCallback(async () => {
    if (!isTTSLoaded) return;

    setIsLoading(true);
    setError(null);

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();

    try {
      const response = await fetch(`${baseUrl}/load-music`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        signal: abortControllerRef.current.signal
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (!Array.isArray(data)) {
        console.warn('Received non-array data:', data);
        setMusicFiles([]);
        return;
      }

      setMusicFiles(data);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error('Error fetching music index:', error);
        setError(`Failed to load music index: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
    }
  }, [isTTSLoaded, baseUrl]);

  useEffect(() => {
    if (isTTSLoaded) {
      fetchMusicIndex();
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [isTTSLoaded, fetchMusicIndex]);

  const handleChange = (e) => {
    const fileName = e.target.value;
    setSelectedMusic(fileName);
    onMusicSelect(fileName);
  };

  const dropdownClasses = [
    'music-library-dropdown',
    isSessionCreated && 'session-created',
    isMobile && 'mobile',
    isTablet && 'tablet'
  ].filter(Boolean).join(' ');

  return (
    <div className={dropdownClasses}>
      {isLoading ? (
        <div className="loading-state">Loading music...</div>
      ) : error ? (
        <div className="error-state">{error}</div>
      ) : (
        <select
          onChange={handleChange}
          value={selectedMusic}
          disabled={!isTTSLoaded || isLoading}
          style={{ fontSize: getFontSize() }}
        >
          <option value="">Select Background Music</option>
          {musicFiles.map((file, index) => (
            <option key={index} value={file.name}>
              {file.name}
            </option>
          ))}
        </select>
      )}
    </div>
  );
};

export default MusicLibraryDropdown;