import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import CreateButton from './components/createButton';
import './App.css';
import './components/Button.css';
import Header from './components/Header';
import VoiceSelector from './components/VoiceSelector';
import VolumeControls from './components/VolumeControls';
import PushToSpeakButton from './components/PushToSpeakButton';
import { Preloader } from './components/Preloader';
import MusicLibraryDropdown from './components/MusicLibraryDropdown';
import DecisionTree from './components/DecisionTree';
import AudioPlayer from './components/AudioPlayer';
import ExportButton from './components/ExportButton';
import { ConversationProvider } from './components/ConversationContext';
import MergedTextEditor from './components/MergedTextEditor';
import debounce from 'lodash/debounce';



import logo from '../../assets/logot.png';


const LoadingController = {
  startTime: null,
  minimumDuration: 2000,

  start() {
    this.startTime = Date.now();
  },

  shouldKeepLoading() {
    if (!this.startTime) return false;
    const elapsed = Date.now() - this.startTime;
    return elapsed < this.minimumDuration;
  },

  getRemainingTime() {
    if (!this.startTime) return 0;
    const elapsed = Date.now() - this.startTime;
    return Math.max(0, this.minimumDuration - elapsed);
  },

  reset() {
    this.startTime = null;
  }
};

const App = () => {
  // Base URL constant
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  // Refs (defined first as they're used in initial states)
  const abortControllerRef = useRef(null);
  const hasGeneratedScript = useRef(false);
  const audioLoadTimeoutRef = useRef(null); 
  
  const safeSetAudioUrl = useCallback((url) => {
    setAudioUrl((prevUrl) => (prevUrl !== url ? url : prevUrl));
  }, []); 

  const loadingStartTimeRef = useRef(Date.now());
  const loadingStateRef = useRef({
    isLoading: false,
    action: null,
    startTime: Date.now()
  });
  const loadingProcessRef = useRef({
    startTime: Date.now(),
    isComplete: false
  }); // Add this ref for the loading process

  // State declarations
  const [customPrompt, setCustomPrompt] = useState('');
  const [meditationScript, setMeditationScript] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState('onyx');
  const [mixedAudioUrl, setMixedAudioUrl] = useState('');
  const [isTTSLoaded, setIsTTSLoaded] = useState(false);
  const [showDecisionTree, setShowDecisionTree] = useState(false);
  const [isCreatingSession, setIsCreatingSession] = useState(false);
  const [isTranscribed, setIsTranscribed] = useState(false);
  const [isScriptGenerated, setIsScriptGenerated] = useState(false);
  const [selectedMusic, setSelectedMusic] = useState('');
  const [isPersonalizationMode, setIsPersonalizationMode] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [personalizedNote, setPersonalizedNote] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [isTextEditorGlowing, setIsTextEditorGlowing] = useState(false);
  const [isEnhancedEditorGlowing, setIsEnhancedEditorGlowing] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [ttsKey, setTTSKey] = useState('');
  const [audioUrl, setAudioUrl] = useState('');  
  const [error, setError] = useState(null);
  const [audioError, setAudioError] = useState(null);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isRecordingAnimation, setIsRecordingAnimation] = useState(false);
  const [isScriptLoading, setIsScriptLoading] = useState(false);
  const [isMixingAudio, setIsMixingAudio] = useState(false);
  const [isSessionCreated, setIsSessionCreated] = useState(false);
  const [isMusicLoading, setIsMusicLoading] = useState(false);
  const debounceTimeoutRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editorMode, setEditorMode] = useState('prompt');
  const [volume, setVolume] = useState({
  
  
    narration: 100,
    music: 20,
    overall: 100,
  });
  const [filters, setFilters] = useState({
    stereoPan: { enabled: false, expanded: false, value: 50, speed: 20, hold_time: 30, pattern: 'sine' },
    binauralBeats: { enabled: false, expanded: false, base_frequency: 100, beat_frequency: 2, volume: 50 },
    oceanWaves: { enabled: false, expanded: false, volume: 50 },
    reverb: { enabled: false, expanded: false, value: 7 },
  });
  const [audioKey, setAudioKey] = useState(Date.now());
  const [isAudioLoading, setIsAudioLoading] = useState(false);
  const [isMixedAudioLoaded, setIsMixedAudioLoaded] = useState(false);

  const fetchMixedAudio = useCallback(async (volumeSettings, musicFilename, currentFilters, ttsFilename) => {
    if (isMixingAudio) {
      console.log('Already mixing audio, skipping request');
      return;
    }
  
    try {
      setIsMixingAudio(true);
      setIsAudioLoading(true);
      setError(null);
  
      const cleanTtsKey = ttsFilename.includes('TTS/') 
        ? ttsFilename.split('TTS/')[1].split('?')[0]
        : ttsFilename;
  
      const requestBody = {
        bg_volume: volumeSettings.bg_volume,
        tts_volume: volumeSettings.tts_volume,
        overall_volume: volumeSettings.overall_volume,
        background_music_url: musicFilename,
        filters: currentFilters,
        tts_filename: cleanTtsKey,
      };
  
      console.log('Sending mix request with body:', requestBody);
  
      const response = await fetch(`${baseUrl}/get-mixed-audio`, {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include',
        mode: 'cors',
        body: JSON.stringify(requestBody)
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Mixing failed: ${response.status} ${errorText}`);
      }
  
      const data = await response.json();
      
      if (!data.audio_url) {
        throw new Error('No audio URL in response');
      }
  
      setAudioUrl(data.audio_url);
      setMixedAudioUrl(data.audio_url);
      setAudioKey(Date.now());
  
    } catch (error) {
      console.error('Detailed mixing error:', error);
      setError(`Audio mixing failed: ${error.message}`);
      
      if (audioUrl && audioUrl.includes('TTS')) {
        setAudioUrl(audioUrl);
        setMixedAudioUrl(audioUrl);
      }
    } finally {
      setIsMixingAudio(false);
      setIsAudioLoading(false);
    }
  }, [baseUrl, audioUrl, isMixingAudio]);

    const debouncedFetchMixedAudio = useMemo(() => 
    debounce(fetchMixedAudio, 300),
    [fetchMixedAudio]
  );

        // Clean up the debounced function on unmount
        useEffect(() => {
          return () => {
            debouncedFetchMixedAudio.cancel();
          };
        }, [debouncedFetchMixedAudio]);

  // Initial parameter state
  const [currentAudioParams, setCurrentAudioParams] = useState({
    bg_volume: volume.music / 100,
    tts_volume: volume.narration / 100,
    overall_volume: volume.overall / 100,
    filters: filters,
    musicFilename: selectedMusic,
    ttsKey: ttsKey,
  });

  // Base utility functions
  const cleanText = useCallback((text) => {
    const cleaned = text
      .replace(/\s*\[PROSODY_START\]\s*|\s*\[PROSODY_END\]\s*|\s*\[PAUSE( LONG)?\]\s*/g, ' ')
      .replace(/\s+/g, ' ')
      .trim();
    return cleaned;
  }, []);

  // Memoized values (defined early as they're used throughout)
  const memoizedVolume = useMemo(() => ({
    narration: volume.narration,
    music: volume.music,
    overall: volume.overall
  }), [volume.narration, volume.music, volume.overall]);

  const audioStatesRef = useRef({
    isTTSReady: false,
    isMusicReady: false,
    isCurrentlyMixing: false
  });



  const handleFilterChange = useCallback((filterName, changes) => {
    setFilters(prevFilters => {
      const updatedFilters = {
        ...prevFilters,
        [filterName]: {
          ...prevFilters[filterName],
          ...changes
        }
      };
  
      if (selectedMusic && ttsKey && isTTSLoaded) {
        const volumeSettings = {
          bg_volume: volume.music / 100,
          tts_volume: volume.narration / 100,
          overall_volume: volume.overall / 100
        };
  
        debouncedFetchMixedAudio(
          volumeSettings,
          selectedMusic,
          updatedFilters,
          ttsKey
        );
      }
  
      return updatedFilters;
    });
  }, [selectedMusic, ttsKey, isTTSLoaded, volume, debouncedFetchMixedAudio]);




// Update handleFilterToggle to trigger remixing
const handleFilterToggle = useCallback((filterName) => {
  setFilters(prevFilters => {
    const updatedFilters = {
      ...prevFilters,
      [filterName]: {
        ...prevFilters[filterName],
        enabled: !prevFilters[filterName].enabled
      }
    };

    if (selectedMusic && ttsKey && isTTSLoaded) {
      const volumeSettings = {
        bg_volume: volume.music / 100,
        tts_volume: volume.narration / 100,
        overall_volume: volume.overall / 100
      };

      debouncedFetchMixedAudio(
        volumeSettings,
        selectedMusic,
        updatedFilters,
        ttsKey
      );
    }

    return updatedFilters;
  });
}, [selectedMusic, ttsKey, isTTSLoaded, volume, debouncedFetchMixedAudio]);


  const handleAudioLoaded = useCallback(() => {
    setIsMixedAudioLoaded(true);
  }, []);

  const handleAudioError = useCallback((error) => {
    setAudioError(`Audio playback error: ${error.message}`);
    setError(`Audio playback error: ${error.message}`);
  }, []);

  const handlePlayingStateChange = useCallback((playingState) => {
    setIsPlaying(playingState);
  }, []);

  const handleAudioComplete = async (transcription) => {
    if (transcription) {
      try {
        // Process transcription
        setCustomPrompt(transcription);
        setIsTranscribed(true);
        setIsTextEditorGlowing(true);
        setTimeout(() => setIsTextEditorGlowing(false), 2000);
        
        // End transcribing state
        setIsTranscribing(false);
        
        // Small delay to ensure UI updates
        await new Promise(resolve => setTimeout(resolve, 100));
        
        // Start script generation
        setIsScriptLoading(true);
        LoadingController.start();  // Start loading session
        await handleGenerateScript(transcription);
        
      } catch (error) {
        console.error('Error:', error);
        setErrorMessage('Failed to process recording. Please try again.');
      }
    } else {
      setErrorMessage('Transcription failed. Please try again.');
      setIsTranscribing(false);
    }
  };
  

  const onVolumeChange = (type, newValue) => {
    const clampedValue = Math.max(0, Math.min(100, newValue));
  
    // Set the new volume
    setVolume((prevVolume) => ({
      ...prevVolume,
      [type]: clampedValue,
    }));
  
    // Visual update for slider background
    requestAnimationFrame(() => {
      const sliderElement = document.querySelector(`#${type}-slider`);
      if (sliderElement) {
        updateSliderBackground(sliderElement, clampedValue);
      }
    });
  
    // Clear any pending debounce and set a new one
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      // Logic to handle volume changes with debounce
      // e.g., update the mixed audio or call a backend API
    }, 300);  // Adjust debounce time as needed
  };
  
  const updateSliderBackground = (sliderElement, value) => {
    sliderElement.style.backgroundSize = `${value}% 100%`;
  };
  
  const handleVolumeChange = useCallback(
    (type, newValue) => {
      const clampedValue = Math.max(0, Math.min(100, newValue));
  
      // Update the volume state for UI feedback
      setVolume(prevVolume => {
        const updatedVolume = {
          ...prevVolume,
          [type]: clampedValue
        };
  
        // Prepare the volume settings for the backend
        const volumeSettings = {
          bg_volume: updatedVolume.music / 100,
          tts_volume: updatedVolume.narration / 100,
          overall_volume: updatedVolume.overall / 100
        };
  
        // Only fetch mixed audio if the required components are loaded
        if (selectedMusic && ttsKey && isTTSLoaded) {
          if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
          }
  
          debounceTimeoutRef.current = setTimeout(() => {
            debouncedFetchMixedAudio(volumeSettings, selectedMusic, filters, ttsKey);
          }, 300);
        }
  
        return updatedVolume;
      });
    },
    [selectedMusic, ttsKey, isTTSLoaded, filters, debouncedFetchMixedAudio]
  );

  

  const loadingState = useMemo(() => {
    const isCurrentlyLoading = 
      isTranscribing || 
      isScriptLoading || 
      LoadingController.shouldKeepLoading();
  
    const currentAction = isTranscribing
      ? 'transcribing'
      : isScriptLoading
      ? 'generating-script'
      : null;
  
    return {
      isAnyLoading: isCurrentlyLoading,
      currentAction
    };
  }, [isTranscribing, isScriptLoading]);


  const handleGenerateScript = useCallback(async (personalizedPrompt = '') => {
    if (isScriptLoading) return;
  
    try {
      setIsScriptLoading(true);
      if (!LoadingController.startTime) {
        LoadingController.start(); // Start loading if not already started
      }
  
      const prompt = personalizedPrompt || customPrompt.trim();
      if (!prompt) {
        throw new Error('Cannot generate a script with an empty prompt.');
      }
  
      const response = await fetch(`${baseUrl}/generate-ssml`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt,
          selected_options: selectedOptions.map(option => option.label),
          selected_voice: selectedVoice,
          transcription: customPrompt,
        }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const data = await response.json();
  
      if (!data.ssml_script) {
        throw new Error('No script received from server');
      }
  
      const cleanedScript = cleanText(data.ssml_script);
      
      // Ensure minimum loading time
      const remainingTime = LoadingController.getRemainingTime();
      await new Promise(resolve => setTimeout(resolve, remainingTime));
  
      // Update states together
      setMeditationScript(cleanedScript);
      setIsScriptGenerated(true);
      setEditorMode('script');
  
    } catch (error) {
      console.error('Error:', error);
      setErrorMessage(error.message);
    } finally {
      // Reset states in correct order
      setIsPersonalizationMode(false);
      setShowDecisionTree(false);
      setIsScriptLoading(false);
      LoadingController.reset();
    }
  }, [
    customPrompt,
    selectedVoice,
    selectedOptions,
    baseUrl,
    isScriptLoading,
    cleanText
  ]);

  
  useEffect(() => {
    setIsLoading(loadingState.isAnyLoading);
  }, [loadingState.isAnyLoading]);


useEffect(() => {
  if (!loadingState.isAnyLoading && LoadingController.shouldKeepLoading()) {
    const timer = setTimeout(() => {
      LoadingController.reset();
    }, LoadingController.getRemainingTime());
    return () => clearTimeout(timer);
  }
}, [loadingState.isAnyLoading]);


  const handleCreateSession = useCallback(async () => {
    if (!meditationScript.trim()) {
      setErrorMessage('Meditation script cannot be empty.');
      return;
    }
  
    setIsCreatingSession(true);
    setIsAudioLoading(true);  // Add this
    setErrorMessage(null);
    setIsTTSLoaded(false);
    setIsSessionCreated(false);
    setMixedAudioUrl('');
    setAudioUrl('');
  
    try {
      console.log("Sending request to synthesize speech...");
      const response = await fetch(`${baseUrl}/synthesize-speech`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: meditationScript, voice: selectedVoice }),
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Speech synthesis failed: ${response.status} ${errorText}`);
      }
  
      const data = await response.json();
      console.log("Received response from synthesize-speech:", data);
  
      if (!data.file_path || !data.filename) {
        throw new Error('Invalid response from server - missing file path or filename');
      }
  
      const cleanKey = data.filename.includes('?')
        ? data.filename.split('?')[0]
        : data.filename;
  
      // Add delay to ensure S3 URL is propagated
      if (data.file_path.includes('s3.amazonaws.com')) {
        await new Promise(resolve => setTimeout(resolve, 1000));
      }
  
      // Set states in specific order to ensure smooth transitions
      setTTSKey(cleanKey);
      setAudioUrl(data.file_path);
      setMixedAudioUrl(data.file_path);
      setAudioKey(Date.now());
      // Set these last to ensure audio is ready
      setIsTTSLoaded(true);
      setIsSessionCreated(true);
  
      console.log('TTS Audio ready, waiting for music selection to mix.');
  
    } catch (error) {
      console.error('Error during session creation:', error);
      setErrorMessage(error.message);
      setIsTTSLoaded(false);
      setIsSessionCreated(false);
      setMixedAudioUrl('');
      setAudioUrl('');
    } finally {
      setIsCreatingSession(false);
      setIsAudioLoading(false);  // Add this
    }
  }, [
    meditationScript,
    selectedVoice,
    baseUrl
  ]);
  

  const handleMusicSelect = useCallback(async (filename) => {
    console.log('Music select triggered with filename:', filename);
  
    if (!filename) {
      console.error('No filename provided');
      return;
    }
  
    setIsMusicLoading(true);
    setSelectedMusic(filename);
  
    if (isTTSLoaded && ttsKey) {
      try {
        const volumeSettings = {
          bg_volume: volume.music / 100,
          tts_volume: volume.narration / 100,
          overall_volume: volume.overall / 100,
        };
  
        console.log('Attempting mix with:', {
          volumeSettings,
          filename,
          ttsKey,
          isTTSLoaded
        });
  
        await fetchMixedAudio(
          volumeSettings,
          filename,
          filters,
          ttsKey
        );
      } catch (error) {
        console.error('Music selection error:', error);
        setError(error.message);
        setSelectedMusic('');
      } finally {
        setIsMusicLoading(false);
      }
    } else {
      console.log('Deferring mix - Current state:', { 
        isTTSLoaded, 
        ttsKey,
        filename 
      });
      setIsMusicLoading(false);
    }
  }, [isTTSLoaded, volume, filters, ttsKey, fetchMixedAudio]);


  useEffect(() => {
    let isMounted = true;
    let debounceTimer;
  
    const attemptMix = async () => {
      // Don't attempt to mix if any required parameter is missing
      if (!isTTSLoaded || !selectedMusic || !ttsKey || isMixingAudio) {
        return;
      }
  
      // Clear any existing debounce timer
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
  
      // Set a new debounce timer
      debounceTimer = setTimeout(async () => {
        const volumeSettings = {
          bg_volume: volume.music / 100,
          tts_volume: volume.narration / 100,
          overall_volume: volume.overall / 100,
        };
        
        try {
          if (isMounted) {
            await fetchMixedAudio(volumeSettings, selectedMusic, filters, ttsKey);
          }
        } catch (error) {
          console.error('Mix effect error:', error);
        }
      }, 300); // 300ms debounce
    };
  
    attemptMix();
  
    // Cleanup function
    return () => {
      isMounted = false;
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [isTTSLoaded, selectedMusic, ttsKey, volume, filters, fetchMixedAudio, isMixingAudio]);

  
  const resetHasGeneratedScript = useCallback(() => {
    hasGeneratedScript.current = false;
  }, []);

  const handlePromptKeyPress = useCallback((event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleGenerateScript();
    }
  }, [handleGenerateScript]);

  const handleScriptKeyPress = useCallback((event) => {
    if (event.key === 'Enter') {
      handleCreateSession();
    }
  }, [handleCreateSession]);

  const handleTextEditorChange = useCallback((e) => {
    const newValue = e.target.value;
    if (editorMode === 'prompt') {
      setCustomPrompt(newValue);
    } else {
      setMeditationScript(newValue);
      setIsScriptGenerated(false);
    }
  }, [editorMode]);

  const resetForNewMeditation = useCallback(() => {
    setCustomPrompt('');
    setMeditationScript('');
    setSelectedOptions([]);
    setIsScriptGenerated(false);
    setIsSessionCreated(false);
    setIsPersonalizationMode(false);
    setErrorMessage('');
    setIsTextEditorGlowing(false);
    setIsEnhancedEditorGlowing(false);
    hasGeneratedScript.current = false;
  }, []);



  return (
    <ConversationProvider>
      <div className="App">
        {/* Header Section */}
        <div className="header-container">
          <div className="header">
            <img src={logo} alt="App Logo" className="app-logo" />
          </div>
        </div>
  
        {/* Meditation Content */}
        <div className={`meditation-content ${showDecisionTree ? 'decision-tree-open' : ''}`}>
          {/* Decision Tree Section */}
          {!isPersonalizationMode && (
            <div className="tree-control-container">
              <div
                className="wave-btn-container"
                onTouchStart={(e) => {
                  const element = e.currentTarget;
                  if (!element) return;
                  element.classList.add('touched');
                  setTimeout(() => element.classList.remove('touched'), 3000);
                }}
              >
                <div className="wave-btn-tooltip">
                  Create a guided meditation session with step-by-step options and prompts
                </div>
                <button
                  className="wave-btn"
                  onClick={() => {
                    resetForNewMeditation();
                    setShowDecisionTree((prev) => !prev);
                  }}
                  aria-label="Open meditation guide"
                >
                  <span className="wave-effect" />
                  <span className="wave-text">Guide</span>
                </button>
              </div>
  
              {showDecisionTree && (
                <div className="decision-tree-wrapper">
                  <DecisionTree
                    onScriptGenerated={handleGenerateScript}
                    customPrompt={customPrompt}
                    setCustomPrompt={setCustomPrompt}
                    setMeditationScript={setMeditationScript}
                    isScriptLoading={isScriptLoading}
                    setIsScriptLoading={setIsScriptLoading}
                    PushToSpeakButton={PushToSpeakButton}
                    isPersonalizationMode={isPersonalizationMode}
                    setIsPersonalizationMode={setIsPersonalizationMode}
                    selectedOptions={selectedOptions}
                    setSelectedOptions={setSelectedOptions}
                    setShowDecisionTree={setShowDecisionTree}
                    isScriptGenerated={isScriptGenerated}
                    personalizedNote={personalizedNote}
                    setPersonalizedNote={setPersonalizedNote}
                    resetForNewMeditation={resetForNewMeditation}
                    selectedVoice={selectedVoice}
                    setIsScriptGenerated={setIsScriptGenerated}
                    setEditorMode={setEditorMode}
                    setErrorMessage={setErrorMessage}
                    cleanText={cleanText}
                    LoadingController={LoadingController}
                  />
                </div>
              )}
            </div>
          )}
  
          {/* Preloader */}
          {(isScriptLoading || isTranscribing || isCreatingSession) && (
            <div className="preloader">
              <Preloader
                isLoading={true}
                action={
                  isTranscribing
                    ? 'transcribing'
                    : isScriptLoading
                    ? 'generating-script'
                    : 'creating-session'
                }
              />
            </div>
          )}
  
          {/* Push to Speak Section */}
          <div className="main-controls">
            <PushToSpeakButton
              onRecordingComplete={handleAudioComplete}
              isRecording={isRecording}
              onRecordingToggle={(isRecording) => {
                setIsRecording(isRecording);
                if (!isRecording) {
                  setIsRecordingAnimation(false);
                  setIsTranscribing(true);
                } else {
                  resetForNewMeditation();
                  setIsRecordingAnimation(true);
                  setIsTranscribing(false);
                }
              }}
              isTranscribing={isTranscribing}
              setIsRecordingAnimation={setIsRecordingAnimation}
              disabled={isLoading}
              isPersonalizationMode={isPersonalizationMode}
              resetHasGeneratedScript={resetHasGeneratedScript}
            />
          </div>
  
          {/* Voice Selector */}
          <div className="voice-selector-container">
            <VoiceSelector 
              selectedVoice={selectedVoice} 
              onVoiceChange={setSelectedVoice} 
            />
          </div>
  
          {/* Merged Text Editor */}
          <MergedTextEditor
            mode={editorMode}
            placeholder={
              editorMode === 'prompt'
                ? isPersonalizationMode
                  ? 'Record or type to personalize your meditation:'
                  : 'Enter a custom prompt...'
                : 'Meditation script goes here...'
            }
            value={editorMode === 'prompt' ? customPrompt : meditationScript}
            onChange={handleTextEditorChange}
            onKeyDown={editorMode === 'prompt' ? handlePromptKeyPress : handleScriptKeyPress}
            isTranscribed={isTranscribed}
            isScriptGenerated={isScriptGenerated}
            onGenerateScript={() => handleGenerateScript(customPrompt)}
            isScriptLoading={isScriptLoading}
            isPersonalizationMode={isPersonalizationMode}
            isGlowing={editorMode === 'prompt' ? isTextEditorGlowing : isEnhancedEditorGlowing}
            setIsGlowing={editorMode === 'prompt' ? setIsTextEditorGlowing : setIsEnhancedEditorGlowing}
            isSessionCreated={isSessionCreated}
          />
  
          {/* Create Button */}
          <CreateButton
            isCreatingSession={isCreatingSession}
            isScriptGenerated={isScriptGenerated}
            isSessionCreated={isSessionCreated}
            onClick={handleCreateSession}
          />
  
          {/* Error Messages */}
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {audioError && <div className="error-message">{audioError}</div>}
  
          {/* Audio Controls */}
          {isTTSLoaded && (
            <div className="audio-controls-container">
              <MusicLibraryDropdown
                onMusicSelect={handleMusicSelect}
                isTTSLoaded={isTTSLoaded}
                isSessionCreated={isSessionCreated}
              />
  
              {selectedMusic && (
                <>
                  {(isMixingAudio || isMusicLoading) ? (
                    <div className="player-preloader-container">
                      <Preloader
                        isLoading={true}
                        action={isMixingAudio ? 'mixing-audio' : 'loading-music'}
                      />
                    </div>
                  ) : (
                    mixedAudioUrl && (
                      <AudioPlayer
                        initialAudioUrl={mixedAudioUrl}
                        volume={memoizedVolume.overall / 100}
                        onError={handleAudioError}
                        onPlayingStateChange={handlePlayingStateChange}
                        onAudioLoaded={handleAudioLoaded}
                      />
                    )
                  )}
  
                  <VolumeControls
                    volume={volume}
                    setVolume={setVolume}
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    onFilterToggle={handleFilterToggle}
                    setFilters={setFilters}
                    isPlaying={isPlaying}
                    error={audioError}
                    isTTSLoaded={isTTSLoaded}
                    selectedMusic={selectedMusic}
                    ttsKey={ttsKey}
                    debouncedFetchMixedAudio={debouncedFetchMixedAudio}
                    disabled={isMixingAudio || loadingState.isAnyLoading}
                  />
                </>
              )}
            </div>
          )}
  
          {/* Export Button */}
          {mixedAudioUrl && !isMixingAudio && (
            <ExportButton text="Export Audio" mixedAudioUrl={mixedAudioUrl} />
          )}
        </div>
      </div>
    </ConversationProvider>
  );
};

export default App;