import React, { useState } from 'react';
import { auth } from '../../utils/firebase';
import { sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ResendVerificationPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleResendVerification = async () => {
    setIsLoading(true);
    try {
      const user = auth.currentUser;
      if (user) {
        await sendEmailVerification(user);
        toast.success('Verification email has been resent. Please check your inbox.');
        // Navigate to check email page after successful resend
        navigate('/check-email');
      } else {
        toast.error('No user is signed in.');
        navigate('/login');
      }
    } catch (error) {
      toast.error('Failed to resend verification email. Please try again.');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="resend-verification-page">
      <h1>Resend Verification Email</h1>
      <p>Click the button below to resend the verification email.</p>
      <button onClick={handleResendVerification} disabled={isLoading}>
        {isLoading ? 'Sending...' : 'Resend Email'}
      </button>
    </div>
  );
};

export default ResendVerificationPage;