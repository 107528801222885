import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { NotificationProvider, useNotifications } from './pages/Dashboard/components/contexts/NotificationContext';
import { ThemeProvider } from './pages/Dashboard/components/contexts/ThemeContext';
import AuthProvider, { PrivateRoute, PublicRoute, EmailVerificationRoute } from './contexts/AuthContext';

// Page imports
import LandingPage from './pages/LandingPage/LandingPage';
import LoginPage from './pages/LoginPage/LoginPage';
import SignupPage from './pages/SignupPage/SignupPage';
import CheckEmailPage from './pages/SignupPage/CheckEmailPage';
import ResendVerificationPage from './pages/SignupPage/ResendVerificationPage';
import VerificationConfirmationPage from './pages/SignupPage/VerificationConfirmationPage';
import MeditationApp from './pages/MeditationApp/MeditationApp';
import MoodTrackerPage from './pages/Dashboard/sections/MoodTrackerPage/MoodTrackerPage';
import NotificationsPage from './pages/Dashboard/sections/NotificationsPage/NotificationsPage';
import SettingsPage from './pages/Dashboard/sections/SettingsPage/SettingsPage';

// Layout
import AppLayout from './pages/Dashboard/components/layout/AppLayout';

// Styles
import 'aos/dist/aos.css';
import './App.css';
import './pages/Dashboard/components/themes/theme-customizer.css';
import './pages/Dashboard/sections/MoodTrackerPage/MoodTrackerPage.css';

// 404 Page Component
const NotFoundPage = () => (
  <div className="not-found">
    <h1>404</h1>
    <p>Page not found</p>
  </div>
);

// Helper to add a welcome notification
const WelcomeNotification = () => {
  const { addNotification } = useNotifications();

  useEffect(() => {
    const alreadyNotified = localStorage.getItem('welcomeNotification');
    if (!alreadyNotified) {
      addNotification({
        type: 'info',
        title: 'Welcome to Pause!',
        message: 'Enjoy a journey of relaxation and mindfulness.',
      });
      localStorage.setItem('welcomeNotification', 'true');
    }
  }, [addNotification]);

  return null;
};

const App = () => {
  useEffect(() => {
    const AOS = window.AOS;
    AOS?.init({
      duration: 1000,
      once: true,
      mirror: false,
    });
  }, []);

  return (
    <div className="wellness-root">
      <NotificationProvider>
        <WelcomeNotification />
        <AuthProvider>
          <ThemeProvider>
            <div className="App">
              <Routes>
                {/* Public landing page */}
                <Route path="/" element={<LandingPage />} />

                {/* Authentication routes */}
                <Route
                  path="/login"
                  element={
                    <PublicRoute>
                      <LoginPage />
                    </PublicRoute>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <PublicRoute>
                      <SignupPage />
                    </PublicRoute>
                  }
                />

                {/* Email verification flow */}
                <Route
                  path="/check-email"
                  element={
                    <EmailVerificationRoute>
                      <CheckEmailPage />
                    </EmailVerificationRoute>
                  }
                />
                <Route
                  path="/verify-success"
                  element={
                    <EmailVerificationRoute>
                      <VerificationConfirmationPage />
                    </EmailVerificationRoute>
                  }
                />
                <Route
                  path="/resend-verification"
                  element={
                    <EmailVerificationRoute>
                      <ResendVerificationPage />
                    </EmailVerificationRoute>
                  }
                />

            {/* Protected app routes */}
            <Route
              path="/app"
              element={
                <PrivateRoute>
                  <AppLayout />
                </PrivateRoute>
              }
            >
              <Route index element={<Navigate to="meditation" replace />} />
              <Route path="meditation" element={<MeditationApp />} />
              <Route path="mood" element={<MoodTrackerPage />} />
              <Route path="notifications" element={<NotificationsPage />} />
              <Route path="settings" element={<SettingsPage />} />
            </Route>

                {/* 404 catch-all */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </div>
          </ThemeProvider>
        </AuthProvider>
      </NotificationProvider>
    </div>
  );
};

export default App;
