import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Outlet } from 'react-router-dom';
import AppHeader from './AppHeader';
import Sidebar from './Sidebar';
import NotificationsPanel from './NotificationsPanel';
import { useTheme } from '../contexts/ThemeContext';
import './AppLayout.css';

const AppLayout = () => {
  const { isDarkMode } = useTheme();
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth > 768);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([
    { id: 1, type: 'info', title: 'Welcome!', message: 'Thank you for joining us.' }
  ]);

  const handleClearNotification = (id) => {
    setNotifications(prev => prev.filter(notification => notification.id !== id));
  };

  const handleClearAllNotifications = () => {
    setNotifications([]);
  };

  const handleMarkAsRead = (id) => {
    setNotifications(prev => prev.map(notification => 
      notification.id === id ? { ...notification, read: true } : notification
    ));
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className={`app-container ${isDarkMode ? 'dark-mode' : ''}`}>
      <AppHeader
        onToggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)}
        onToggleNotifications={() => setIsNotificationsOpen(!isNotificationsOpen)}
        notificationsCount={notifications.length}
        isSidebarOpen={isSidebarOpen}
        isNotificationsOpen={isNotificationsOpen}
        onCloseNotifications={() => setIsNotificationsOpen(false)}
        notifications={notifications}
        onMarkAsRead={handleMarkAsRead}
        onClear={handleClearNotification}
        onClearAll={handleClearAllNotifications}
      />
      <div className="flex h-[calc(100vh-64px)]">
        <Sidebar isOpen={isSidebarOpen} />
        <main className="content-area">
          <Outlet />
        </main>
        <AnimatePresence>
          {isNotificationsOpen && (
            <NotificationsPanel
              notifications={notifications}
              onMarkAsRead={handleMarkAsRead}
              onClear={handleClearNotification}
              onClearAll={handleClearAllNotifications}
              onCloseNotifications={() => setIsNotificationsOpen(false)}
            />
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AppLayout;