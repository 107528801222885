import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import gsap from 'gsap';

export const MOOD_OPTIONS = {
  Happy: { emoji: '😊', color: '#FFD93D' },
  Excited: { emoji: '🤩', color: '#FF8400' },
  Peaceful: { emoji: '😌', color: '#6BCB77' },
  Neutral: { emoji: '😐', color: '#89CFF0' },
  Tired: { emoji: '😴', color: '#B983FF' },
  Sad: { emoji: '😢', color: '#4D96FF' },
  Angry: { emoji: '😠', color: '#FF6B6B' },
  None: { emoji: '❌', color: '#FF1700' },
};

export const MONTH_NAMES = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

export const DAYS = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const useCalendar = () => {
  const today = useMemo(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  }, []);

  const [currentYear, setCurrentYear] = useState(today.getFullYear());
  const [currentMonth, setCurrentMonth] = useState(today.getMonth());
  const [showMoodSelector, setShowMoodSelector] = useState(false);
  const [activeCell, setActiveCell] = useState(null);
  const [loadingState, setLoadingState] = useState('loading');
  const [moods, setMoods] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const dayRefs = useRef({});
  const moodSelectorRef = useRef(null);

  const isSameDate = useCallback((date1, date2) => {
    return date1.toLocaleDateString('en-CA') === date2.toLocaleDateString('en-CA');
  }, []);

  const goToNextMonth = useCallback(() => {
    setCurrentMonth((prev) => {
      if (prev === 11) {
        setCurrentYear((year) => year + 1);
        return 0;
      }
      return prev + 1;
    });
  }, []);

  const goToPreviousMonth = useCallback(() => {
    setCurrentMonth((prev) => {
      if (prev === 0) {
        setCurrentYear((year) => year - 1);
        return 11;
      }
      return prev - 1;
    });
  }, []);

  const moodSummary = useMemo(() => {
    const summary = {};
    Object.values(moods).forEach((mood) => {
      summary[mood] = (summary[mood] || 0) + 1;
    });
    return summary;
  }, [moods]);

  const isDateInPast = useCallback((date) => {
    const comparisonDate = new Date(date);
    comparisonDate.setHours(0, 0, 0, 0);
    return comparisonDate < today;
  }, [today]);

  const generateRandomMood = useCallback(() => {
    const moodKeys = Object.keys(MOOD_OPTIONS).filter((key) => key !== 'None');
    return moodKeys[Math.floor(Math.random() * moodKeys.length)];
  }, []);

  const handleDayClick = useCallback((date, dateKey, isPast, event) => {
    event.preventDefault();
    event.stopPropagation();
  
    // Use consistent date format
    const formattedDate = date.toLocaleDateString('en-CA'); // YYYY-MM-DD format
    setSelectedDate(formattedDate);
  
    if (isPast || isSameDate(date, today)) {
      setActiveCell(dateKey);
      setShowMoodSelector(true);
  
      const cell = dayRefs.current[dateKey];
      if (cell) {
        gsap.to(cell, {
          scale: 1.05,
          duration: 0.2,
          ease: 'back.out(1.7)',
        });
      }
    } else {
      setShowMoodSelector(false);
      setActiveCell(null);
    }
  }, [today, isSameDate]);

  const handleMoodSelect = useCallback((mood, dateKey) => {
    if (!dateKey) return;

    setMoods((prevMoods) => {
      const updated = { ...prevMoods };
      if (mood === 'None') {
        delete updated[dateKey];
      } else {
        updated[dateKey] = mood;
      }
      return updated;
    });

    setSelectedDate(dateKey);

    const cell = dayRefs.current[dateKey];
    if (cell) {
      gsap.timeline()
        .to(cell, { scale: 1.1, duration: 0.2, ease: 'back.out(1.7)' })
        .to(cell, { scale: 1, duration: 0.2, ease: 'power2.out', clearProps: 'transform' });
    }

    gsap.to(moodSelectorRef.current, {
      opacity: 0,
      y: -10,
      duration: 0.2,
      ease: 'power2.in',
      onComplete: () => {
        setShowMoodSelector(false);
        setActiveCell(null);
      },
    });
  }, []);

  const generateCalendarDays = useCallback(() => {
    const days = [];
    const firstDay = new Date(currentYear, currentMonth, 1).getDay();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const prevMonthDays = new Date(currentYear, currentMonth, 0).getDate();

    // Previous month days
    for (let i = firstDay - 1; i >= 0; i--) {
      const day = prevMonthDays - i;
      days.push(
        <div key={`prev-${day}`} className="wellness-calendar-day wellness-calendar-day-inactive">
          <div className="wellness-calendar-day-content">
            <span className="wellness-calendar-day-number">{day}</span>
          </div>
        </div>
      );
    }

    // Current month days
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(currentYear, currentMonth, day);
      date.setHours(0, 0, 0, 0);
      const dateKey = date.toISOString().split('T')[0];
      const isToday = isSameDate(date, today);
      const isPast = isDateInPast(date);
      const mood = moods[dateKey];
      const isSelected = dateKey === selectedDate;

      const dayClasses = [
        'wellness-calendar-day',
        isToday ? 'wellness-calendar-current-day' : '',
        mood ? 'wellness-calendar-has-mood' : '',
        activeCell === dateKey ? 'wellness-calendar-active' : '',
        isSelected ? 'wellness-calendar-selected' : '',
        'wellness-calendar-clickable'
      ]
        .filter(Boolean)
        .join(' ');

      days.push(
        <div
          key={dateKey}
          ref={(el) => {
            if (el) dayRefs.current[dateKey] = el;
            else delete dayRefs.current[dateKey];
          }}
          className={dayClasses}
          onClick={(e) => handleDayClick(date, dateKey, isPast, e)}
        >
          <div className="wellness-calendar-day-content">
            <span className="wellness-calendar-day-number">{day}</span>
            {mood && (
              <span 
                className="wellness-calendar-mood-emoji" 
                style={{ color: MOOD_OPTIONS[mood]?.color }}
              >
                {MOOD_OPTIONS[mood]?.emoji}
              </span>
            )}
          </div>
        </div>
      );
    }

    // Next month days
    const totalCells = Math.ceil((daysInMonth + firstDay) / 7) * 7;
    const nextDays = totalCells - (daysInMonth + firstDay);
    for (let i = 1; i <= nextDays; i++) {
      days.push(
        <div key={`next-${i}`} className="wellness-calendar-day wellness-calendar-day-inactive">
          <div className="wellness-calendar-day-content">
            <span className="wellness-calendar-day-number">{i}</span>
          </div>
        </div>
      );
    }

    return days;
  }, [currentYear, currentMonth, moods, activeCell, selectedDate, handleDayClick, isDateInPast, today, isSameDate]);

  return {
    dayRefs,
    currentYear,
    currentMonth,
    showMoodSelector,
    activeCell,
    moods,
    moodSelectorRef,
    handleMoodSelect,
    generateCalendarDays,
    setShowMoodSelector,
    setActiveCell,
    DAYS,
    MOOD_OPTIONS,
    MONTH_NAMES,
    today,
    selectedDate,
    setSelectedDate,
    goToNextMonth,
    goToPreviousMonth,
    moodSummary,
  };
};

export default useCalendar;