import React from 'react';
import './Button.css';

const CreateButton = ({ isCreatingSession, isScriptGenerated, isSessionCreated, onClick }) => {
  const buttonClass = `wave-btn ${
    isCreatingSession
      ? 'creating-session'
      : isScriptGenerated && !isSessionCreated
      ? 'script-generated'
      : ''
  }`;
  
  return (
    <button
      className={buttonClass}
      onClick={onClick}
      disabled={!isScriptGenerated || isCreatingSession || isSessionCreated}
    >
      <span className="wave-effect"></span>
      <span className="wave-text">
        {isCreatingSession ? 'Creating...' : isSessionCreated ? 'Session Created' : 'Create Session'}
      </span>
    </button>
  );
};

export default CreateButton;