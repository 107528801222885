import React, { createContext, useContext, useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  sendPasswordResetEmail,
  onAuthStateChanged,
  OAuthProvider,
  sendEmailVerification,
} from 'firebase/auth';
import { auth } from '../utils/firebase';

// Create the context
const AuthContext = createContext();

// Hook for consuming AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          await currentUser.reload();
          setUser(auth.currentUser);
        } catch (reloadError) {
          console.error('Error reloading user:', reloadError);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Login with email and password
  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      setUser(userCredential.user);
      return userCredential.user;
    } catch (loginError) {
      console.error('Login Error:', loginError);
      throw loginError;
    }
  };

  // Login with Google
  const loginWithGoogle = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      return result.user;
    } catch (googleError) {
      console.error('Google Login Error:', googleError);
      throw googleError;
    }
  };

  // Login with Apple
  const loginWithApple = async () => {
    try {
      const provider = new OAuthProvider('apple.com');
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      return result.user;
    } catch (appleError) {
      console.error('Apple Login Error:', appleError);
      throw appleError;
    }
  };

  // Signup with email and password
  const signup = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      await sendEmailVerification(userCredential.user); // Send email verification
      setUser(userCredential.user);
      return userCredential.user;
    } catch (signupError) {
      console.error('Signup Error:', signupError);
      throw signupError;
    }
  };

  // Logout
  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (logoutError) {
      console.error('Logout Error:', logoutError);
      throw logoutError;
    }
  };

  // Reset Password
  const resetPassword = async (email) => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (resetError) {
      console.error('Password Reset Error:', resetError);
      throw resetError;
    }
  };

  // Context value
  const value = {
    user,
    loading,
    error,
    login,
    loginWithGoogle,
    loginWithApple,
    signup,
    logout,
    resetPassword,
    isAuthenticated: !!user,
    emailVerified: user?.emailVerified || false,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Route Guards
export const PrivateRoute = ({ children }) => {
  const { user, loading, emailVerified } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!emailVerified) {
    return <Navigate to="/check-email" replace />;
  }

  return children;
};

export const PublicRoute = ({ children }) => {
  const { user, loading, emailVerified } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (user && emailVerified) {
    return <Navigate to="/app" replace />;
  }

  return children;
};

export const EmailVerificationRoute = ({ children }) => {
  const { user, loading, emailVerified } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (emailVerified) {
    return <Navigate to="/app" replace />;
  }

  return children;
};

export default AuthProvider;
