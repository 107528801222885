import React, { useState, useEffect, useRef, useCallback } from 'react';
import './PushToSpeakButton.css';
import { THEMES, useTheme } from '../../Dashboard/components/contexts/ThemeContext';


const PushToSpeakButton = ({
  onRecordingComplete,
  isRecording,
  onRecordingToggle,
  isTranscribing,
  setIsRecordingAnimation,
  disabled,
  isPersonalizationMode,
  resetHasGeneratedScript // New prop
}) => {
  const [localIsRecording, setLocalIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [isMobile, setIsMobile] = useState(false);
  const tooltipTimeoutRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const { themeConfig } = useTheme();


  const checkMobile = useCallback(() => setIsMobile(window.innerWidth <= 768), []);

  useEffect(() => {
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, [checkMobile]);

  useEffect(() => {
    setLocalIsRecording(isRecording);
  }, [isRecording]);

  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }
      if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  useEffect(() => {
    const root = document.documentElement;
    const selectedTheme = THEMES[themeConfig.theme];
  
    if (selectedTheme) {
      Object.entries(selectedTheme.colors).forEach(([key, value]) => {
        root.style.setProperty(key, value);
      });
    }
  }, [themeConfig]);


  const handleTooltipToggle = useCallback(() => {
    if (tooltipTimeoutRef.current) {
      clearTimeout(tooltipTimeoutRef.current);
    }
    setShowTooltip(true);
    tooltipTimeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
    }, 3000); // Hide tooltip after 3 seconds
  }, []);

  const handleTranscription = useCallback(async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio_recording.wav');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/transcribe-audio`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const transcriptionText = data?.transcription ?? '';

      if (transcriptionText) {
        onRecordingComplete(transcriptionText);
      } else {
        throw new Error('No transcription available');
      }
    } catch (error) {
      console.error('Error handling transcription:', error);
      onRecordingComplete(null);
      alert(`Transcription failed: ${error.message}. Please try again.`);
    } finally {
      setIsRecordingAnimation(false);
    }
  }, [onRecordingComplete, setIsRecordingAnimation]);

  const startRecording = useCallback(async () => {
    if (!localIsRecording && !disabled) {
      resetHasGeneratedScript(); // Reset here
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };

        mediaRecorderRef.current.start();
        setLocalIsRecording(true);
        onRecordingToggle(true);
      } catch (error) {
        console.error('Error starting the MediaRecorder:', error);
        alert(`Failed to start recording: ${error.message}. Please check your microphone settings and try again.`);
      }
    }
  }, [localIsRecording, disabled, onRecordingToggle, resetHasGeneratedScript]);

  const stopRecording = useCallback(() => {
    if (localIsRecording && mediaRecorderRef.current?.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setLocalIsRecording(false);
      onRecordingToggle(false);
      setIsRecordingAnimation(true);

      mediaRecorderRef.current.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
        audioChunksRef.current = [];

        if (audioBlob.size > 0) {
          await handleTranscription(audioBlob);
        } else {
          console.error('No audio data recorded');
          alert('No audio data recorded. Please try again.');
        }
      };
    }
  }, [localIsRecording, onRecordingToggle, setIsRecordingAnimation, handleTranscription]);


  const handleTouchStart = useCallback((e) => {
    e.preventDefault();
    handleTooltipToggle();
    startRecording();
  }, [startRecording, handleTooltipToggle]);

  useEffect(() => {
    return () => {
      if (tooltipTimeoutRef.current) {
        clearTimeout(tooltipTimeoutRef.current);
      }
    };
  }, []);


  const handleTouchEnd = useCallback((e) => {
    e.preventDefault(); // Prevent default touch behavior
    stopRecording();
  }, [stopRecording]); // `stopRecording` is passed as a dependency

  return (
    <div className={`PushButton-wrapper ${isMobile ? 'mobile' : ''} ${showTooltip ? 'touched' : ''}`}>
      <div className={`PushButton-tooltip ${showTooltip ? 'visible' : ''}`}>
        {isPersonalizationMode 
          ? "Push and hold to record your personalization preferences"
          : "Push and hold to speak your meditation preferences"}
      </div>
      <button
        className={`PushButton ${disabled ? 'disabled' : ''} ${localIsRecording ? 'active' : ''} ${isMobile ? 'mobile' : ''}`}
        onMouseDown={startRecording}
        onMouseUp={stopRecording}
        onMouseLeave={stopRecording}
        onMouseEnter={handleTooltipToggle}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={stopRecording}
        onContextMenu={(e) => e.preventDefault()}
        disabled={disabled || isTranscribing}
        aria-label={localIsRecording ? 'Stop recording' : 'Start recording'}
        aria-pressed={localIsRecording}
      >
        <svg className="PushButton-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M6 19v-7a1 1 0 0 1 2 0v7a1 1 0 0 1-2 0zm5-14v10a1 1 0 0 0 2 0V5a1 1 0 0 0-2 0zm5 4v6a1 1 0 0 0 2 0v-6a1 1 0 0 0-2 0z"/>
        </svg>
      </button>
      <div className={`PushButton-label ${isMobile ? 'mobile' : ''}`}>
        {localIsRecording ? 'Recording...' : isTranscribing ? 'Transcribing...' : isPersonalizationMode ? 'Push to Personalize' : 'Push to Talk'}
      </div>
    </div>
  );
};

export default PushToSpeakButton ;