// src/pages/MeditationApp/hooks/useResponsive.js
import { useState, useEffect } from 'react';

export const useResponsive = () => {
  const [screenSize, setScreenSize] = useState({
    isMobile: window.innerWidth <= 480,
    isTablet: window.innerWidth <= 768 && window.innerWidth > 480,
    isDesktop: window.innerWidth > 768,
    width: window.innerWidth
  });

  useEffect(() => {
    let timeoutId = null;

    const handleResize = () => {
      clearTimeout(timeoutId);
      
      timeoutId = setTimeout(() => {
        setScreenSize({
          isMobile: window.innerWidth <= 480,
          isTablet: window.innerWidth <= 768 && window.innerWidth > 480,
          isDesktop: window.innerWidth > 768,
          width: window.innerWidth
        });
      }, 150); // Debounce delay
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  return screenSize;
};

export default useResponsive;