import React, { forwardRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import './Header.css';

const Header = forwardRef((props, ref) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Tracks the state of the mobile menu
  const [activeSection, setActiveSection] = useState(''); // Tracks the currently active section
  const [isScrolled, setIsScrolled] = useState(false); // Tracks whether the header should have a scrolled state
  const [isHeaderVisible, setIsHeaderVisible] = useState(true); // Tracks visibility of the header on desktop
  const [lastScrollY, setLastScrollY] = useState(0); // Tracks the last Y-scroll position

  // Handles scroll effects for header visibility and active section
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      // Desktop-specific scroll behavior
      if (window.innerWidth > 768) {
        if (currentScrollY > lastScrollY && currentScrollY > 50) {
          setIsHeaderVisible(false); // Hide header on scroll down
        } else {
          setIsHeaderVisible(true); // Show header on scroll up
        }
      }

      setLastScrollY(currentScrollY);

      // Header background changes on scroll
      setIsScrolled(currentScrollY > 50);

      // Active section detection
      const sections = ['features', 'ai-mindfulness', 'cognition'];
      const currentSection = sections.find((section) => {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          return rect.top <= 100 && rect.bottom >= 100;
        }
        return false;
      });

      setActiveSection(currentSection || '');
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [lastScrollY]);

  const scrollToSection = (sectionId) => {
    if (sectionId === 'top') {
      window.scrollTo({
        top: 0, // Scroll to the top of the page
        behavior: 'smooth',
      });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        const headerOffset = 80; // Adjust for the header height
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
  
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  
    setIsMenuOpen(false); // Close the mobile menu when navigating
  };

  // Toggles the mobile menu open/close state
  const toggleMobileMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  return (
    <header
      ref={ref}
      className={`site-header ${isHeaderVisible ? 'visible' : ''} ${isScrolled ? 'scrolled' : ''}`}
    >
      <div className="header-container">
        {/* Left content: Brand and navigation */}
        <div className="left-content">
        <Link to="/" className="brand-text" onClick={() => scrollToSection('top')}>
        PAUSE
        </Link>
          <nav className="nav-links">
            {[
              { id: 'features', label: 'Demo' },
              { id: 'ai-mindfulness', label: 'Mood Tracker' },
              { id: 'cognition', label: 'Science' },
            ].map(({ id, label }) => (
              <button
                key={id}
                onClick={() => scrollToSection(id)}
                className={`nav-link ${activeSection === id ? 'active' : ''}`}
              >
                {label}
              </button>
            ))}
          </nav>
        </div>

        {/* Right content: Authentication and mobile menu */}
        <div className="auth-section">
          <Link to="/login" className="sign-in">
            Sign In
          </Link>
          <Link to="/signup" className="get-started">
            Get Started
          </Link>
          <button
            className={`menu-button ${isMenuOpen ? 'active' : ''}`}
            onClick={toggleMobileMenu}
            aria-label="Toggle menu"
          >
            {isMenuOpen ? <X size={20} /> : <Menu size={20} />}
          </button>
        </div>
      </div>

      {/* Mobile menu */}
      {isMenuOpen && (
        <div className={`mobile-menu ${isMenuOpen ? 'open' : ''}`}>
          <nav className="mobile-nav">
            {[
              { id: 'features', label: 'Demo' },
              { id: 'ai-mindfulness', label: 'Mood Tracker' },
              { id: 'cognition', label: 'Science' },
            ].map(({ id, label }) => (
              <button
                key={id}
                onClick={() => scrollToSection(id)}
                className={`mobile-nav-link ${activeSection === id ? 'active' : ''}`}
              >
                {label}
              </button>
            ))}
            <div className="mobile-auth">
              <Link to="/login" className="mobile-sign-in">
                Sign In
              </Link>
              <Link to="/signup" className="mobile-get-started">
                Get Started
              </Link>
            </div>
          </nav>
        </div>
      )}
    </header>
  );
});

Header.displayName = 'Header';

export default Header;
