import React, { useState, useEffect, useRef } from 'react';
import transcribingGif from '../../../assets/transcribing.gif';
import generatingScriptGif from '../../../assets/generating-script.gif';
import creatingSessionGif from '../../../assets/creating-session.gif';
import mixingAudioGif from '../../../assets/mixing-audio.gif';
import preloaderAudio from '../../../assets/preload.mp3';
import './Preloader.css';

const MINIMUM_LOADING_TIME = 2000; // 2 seconds minimum loading time

const Preloader = ({ isLoading, action }) => {
  const [message, setMessage] = useState('');
  const [shouldShow, setShouldShow] = useState(false);
  const [transitionState, setTransitionState] = useState(''); // For fade animations
  const audioRef = useRef(null);
  const loadingTimerRef = useRef(null);
  const startTimeRef = useRef(null);

  // Initialize audio on mount
  useEffect(() => {
    audioRef.current = new Audio(preloaderAudio);
    
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.src = '';
      }
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
    };
  }, []);

  // Handle loading states and messages
  useEffect(() => {
    const getMessageForAction = (action) => {
      switch (action) {
        case 'transcribing':
          return 'Processing your voice recording...';
        case 'generating-script':
          return 'Creating your custom meditation script...';
        case 'creating-session':
          return 'Creating your tailored meditation session...';
        case 'mixing-audio':
          return 'Mixing your audio...';
        default:
          return 'Loading...';
      }
    };

    // Update message based on action
    setMessage(getMessageForAction(action));

    if (isLoading) {
      // Start new loading session
      if (!startTimeRef.current) {
        startTimeRef.current = Date.now();
        setTransitionState('fade-enter');
        // Remove the fade-enter class after animation
        setTimeout(() => setTransitionState(''), 300);
      }
      setShouldShow(true);

      // Clear any existing timer
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
    } else {
      // Calculate remaining time to meet minimum duration
      const elapsedTime = startTimeRef.current ? Date.now() - startTimeRef.current : 0;
      const remainingTime = Math.max(0, MINIMUM_LOADING_TIME - elapsedTime);

      // Add fade-exit class before hiding
      setTransitionState('fade-exit');

      // Wait for minimum time before hiding
      loadingTimerRef.current = setTimeout(() => {
        setShouldShow(false);
        startTimeRef.current = null;
        setTransitionState('');
      }, remainingTime);
    }

    // Handle audio playback
    const handleAudio = async () => {
      if (!audioRef.current) return;
      
      try {
        if (isLoading) {
          if (audioRef.current.paused) {
            await audioRef.current.play();
          }
        } else {
          audioRef.current.pause();
          audioRef.current.currentTime = 0;
        }
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error handling audio:', error);
        }
      }
    };

    handleAudio();

    return () => {
      if (loadingTimerRef.current) {
        clearTimeout(loadingTimerRef.current);
      }
    };
  }, [isLoading, action]);

  const getGifForAction = (action) => {
    switch (action) {
      case 'generating-script':
        return generatingScriptGif;
      case 'creating-session':
        return creatingSessionGif;
      case 'mixing-audio':
        return mixingAudioGif;
      case 'transcribing':
      default:
        return transcribingGif;
    }
  };

  if (!shouldShow) return null;

  return (
    <div className={`preloader ${transitionState}`}>
      <img
        src={getGifForAction(action)}
        alt={`${action || 'loading'}...`}
        className={`preloader-img ${action || ''}`}
      />
      <div className="message">{message}</div>
    </div>
  );
};

export { Preloader };