import React, { useState, useEffect } from 'react';
import { auth } from '../../utils/firebase';
import { sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import './CheckEmailPage.css';

const CheckEmailPage = () => {
  const [resending, setResending] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const navigate = useNavigate();

  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000;

  const retryOperation = async (operation) => {
    let lastError;
    for (let i = 0; i < MAX_RETRIES; i++) {
      try {
        return await operation();
      } catch (error) {
        lastError = error;
        if (error.code === 'auth/network-request-failed' && i < MAX_RETRIES - 1) {
          await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * (i + 1)));
          setRetryCount(prev => prev + 1);
          continue;
        }
        throw error;
      }
    }
    throw lastError;
  };

  useEffect(() => {
    const checkVerificationStatus = async () => {
      if (auth.currentUser) {
        try {
          await retryOperation(async () => {
            await auth.currentUser.reload();
            if (auth.currentUser.emailVerified) {
              navigate('/app');
            }
          });
        } catch (error) {
          console.error('Error checking verification status:', error);
          // Continue checking even if there's an error
        }
      }
    };

    const interval = setInterval(checkVerificationStatus, 3000);
    return () => clearInterval(interval);
  }, [navigate]);

  const handleResendEmail = async () => {
    setResending(true);
    setRetryCount(0);
    try {
      await retryOperation(async () => {
        await sendEmailVerification(auth.currentUser);
      });
      alert("A fresh breeze is coming your way. We have sent a new verification email!");
    } catch (err) {
      console.error('Error resending email verification:', err);
      if (err.code === 'auth/network-request-failed') {
        alert("Network connection issue. Please check your connection and try again.");
      } else {
        alert("Something went wrong. Please try again.");
      }
    } finally {
      setResending(false);
    }
  };
  
  const handleCheckVerification = async () => {
    setRetryCount(0);
    try {
      await retryOperation(async () => {
        await auth.currentUser.reload();
        if (auth.currentUser.emailVerified) {
          navigate('/app');
        } else {
          alert("Your journey hasn't begun yet. Please verify your email.");
        }
      });
    } catch (error) {
      console.error('Error checking verification:', error);
      if (error.code === 'auth/network-request-failed') {
        alert("Network connection issue. Please check your connection and try again.");
      } else {
        alert("Something went wrong. Please try again.");
      }
    }
  };

  return (
    <div className="check-email-page">
      <div className="background-layer"></div>
      <div className="check-email-container">
        <h1 className="check-email-title">Take the First Step</h1>
        <p className="check-email-subtitle">
          We've sent a gentle nudge to your inbox. Verify your email to unlock your personalized meditation experience.
        </p>
        <button 
          onClick={handleCheckVerification} 
          className="auth-button"
          disabled={retryCount > 0}
        >
          {retryCount > 0 ? 'Retrying...' : "I've Verified My Email"}
        </button>
        <button
          onClick={handleResendEmail}
          className="auth-button secondary"
          disabled={resending}
        >
          {resending ? 
            retryCount > 0 ? 
              `Retrying (${retryCount}/${MAX_RETRIES})...` : 
              'Sending tranquility...' 
            : 'Resend Verification Email'
          }
        </button>
        <p className="auth-footer">
          Need guidance?{' '}
          <a href="/support" className="auth-link">
            Contact our support team
          </a>
        </p>
      </div>
    </div>
  );
};

export default CheckEmailPage;